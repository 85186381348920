import { formatToDollar, formatToPercent } from "@inrev/common";
import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { HiOutlineArrowTopRightOnSquare } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { CSVDownloadButton } from "../../../../components/ui/CSVDownloadButton";
import { DataTable } from "../../../../components/ui/DataTable/DataTable";
import { getStatusTagByStatus } from "../../../../components/ui/StatusTag";
import { bondTypeLabelMap } from "../../../../constants";
import { SubmittedSuretyAccount } from "../../../../domain/agent/account/types";
import { formatEmpty, formatFileName } from "../../../../utils";

type AccountBondsTableProps = {
	account: SubmittedSuretyAccount;
};
const columns: ColumnDef<SubmittedSuretyAccount["bonds"][number]>[] = [
	{
		id: "status",
		accessorKey: "status",
		header: "Status",
		size: 116,
		filterFn: "equalsString",
		cell: (props) => getStatusTagByStatus(props.row.original.status),
	},
	{
		id: "principalName",
		accessorKey: "principalName",
		header: "Principal",
		size: 180,
	},
	{
		id: "type",
		accessorKey: "type",
		header: "Type",
		size: 77,
		accessorFn: ({ type }) => bondTypeLabelMap[type],
	},
	{
		id: "number",
		accessorKey: "number",
		header: "Bond Number",
		size: 100,
	},
	{
		id: "description",
		accessorKey: "description",
		header: "Description",
		accessorFn: ({ description }) => formatEmpty(description),
	},
	{
		id: "obligeeName",
		accessorKey: "obligeeName",
		header: "Obligee Name",
	},
	{
		id: "effectiveDate",
		accessorKey: "effectiveDate",
		accessorFn: ({ effectiveDate }) => DateTime.fromISO(effectiveDate).toJSDate(),
		cell: (props) =>
			DateTime.fromISO(props.row.original.effectiveDate).toLocaleString(DateTime.DATE_MED),
		header: "Effective Date",
		size: 150,
		enableGlobalFilter: false,
	},
	{
		id: "expectedCompletionDate",
		accessorKey: "expectedCompletionDate",
		accessorFn: ({ expectedCompletionDate }) =>
			formatEmpty(
				expectedCompletionDate
					? DateTime.fromISO(expectedCompletionDate).toJSDate()
					: expectedCompletionDate,
			),
		cell: (props) =>
			formatEmpty(
				props.row.original.expectedCompletionDate
					? DateTime.fromISO(props.row.original.expectedCompletionDate).toLocaleString(
							DateTime.DATE_MED,
						)
					: props.row.original.expectedCompletionDate,
			),
		size: 200,
		sortingFn: "datetime",
		header: "Estimated Completion Date",
	},
	{
		id: "amount",
		accessorKey: "amount",
		header: "Amount",
		size: 130,
		cell: (props) => formatEmpty(formatToDollar(props.getValue<string>())),
	},
	{
		id: "rate",
		accessorKey: "rate",
		header: "Rate",
		size: 130,
		accessorFn: ({ rate }) => formatToPercent(rate, 3),
	},
	{
		id: "premium",
		accessorKey: "premium",
		header: "Premium",
		size: 130,
		cell: (context) => formatEmpty(formatToDollar(context.getValue<string>())),
	},
];

export const AccountBondsTable = ({ account }: AccountBondsTableProps) => {
	const navigate = useNavigate();
	const handleBondClick = (bond: SubmittedSuretyAccount["bonds"][number]) => {
		navigate(`/bonds/${bond.id}`);
	};

	if (account.status === "approved") return null;
	return (
		<div className="flex flex-col w-full space-y-[20px]">
			<div className="flex items-center justify-between w-full h-fit min-h-fit">
				<div className="flex items-center space-x-[12px]">
					<span className="text-[20px] text-gray-900 font-medium min-w-fit">Bonds</span>
					<div className="flex items-center justify-center h-[28px] w-[28px] min-w-fit bg-gray-100/70 text-[13px] font-medium rounded-full">
						{account.bonds.length}
					</div>
				</div>
				{account.bonds.length > 0 ? (
					<CSVDownloadButton
						fileName={`${formatFileName(account.displayName)}_bonds_${DateTime.now().toISODate()}`}
						data={account.bonds}
						keys={[
							"status",
							"principalName",
							"type",
							"number",
							"description",
							"effectiveDate",
							"expectedCompletionDate",
							"amount",
							"rate",
							"premium",
							"carrierDisplayName",
						]}
						className="text-gray-600/65 hover:text-gray-800"
						loadingStateClassName="text-gray-400"
					>
						<HiOutlineArrowTopRightOnSquare className="text-[19px]" />
					</CSVDownloadButton>
				) : (
					<></>
				)}
			</div>
			{account.bonds.length > 0 ? (
				<div className="w-full h-fit min-h-fit">
					<DataTable
						name="account_bonds"
						columnDefs={columns}
						data={account.bonds}
						tableBodyBorderColor="gray-200"
						tableClassName="shadow-sm max-h-[400px]"
						onRowClick={handleBondClick}
						rowUrl={(row) => `/bonds/${row.id}`}
						expandToRows
					/>
				</div>
			) : (
				<span className="text-[15px] text-gray-600 font-normal italic">No bonds</span>
			)}
		</div>
	);
};

import Types, { Dtos, UserId } from "@inrev/common";
import { Dropdown } from "../../../components/ui/Dropdown";
import { formatName } from "../../../utils";

export type AdminUserSelectProps = {
	adminUsers: Dtos.Admin.AdminUser.Get.Response[];
	currentAssignee: UserId;
	entity: Types.UnderwriterAssignment.UnderwriterAssignmentEntity;
	onChange: (userId: UserId) => void;
	disabled: boolean;
	className?: string;
};
export const AdminUserSelect = (props: AdminUserSelectProps) => {
	const selectedAdminUser = props.adminUsers.find((u) => u.id === props.currentAssignee);
	const adminOptions = props.adminUsers.map((adminUser) => ({
		label: formatName(adminUser),
		value: adminUser.id,
	}));

	return (
		<Dropdown
			value={selectedAdminUser?.id}
			options={adminOptions}
			onChange={props.onChange}
			disabled={props.disabled}
			className={props.className}
		/>
	);
};

import { Popover, PopoverButton, PopoverPanel, PopoverPanelProps } from "@headlessui/react";
import { cn } from "../lib/utils";

type MenuProps = {
	buttonClassName?: string;
	button: React.ReactNode;
	panelClassName?: string;
	children: React.ReactNode;
	anchor: PopoverPanelProps["anchor"];
};

export const Menu = ({ buttonClassName, button, panelClassName, anchor, children }: MenuProps) => {
	return (
		<Popover className="group">
			<PopoverButton className={cn("outline-0", buttonClassName)}>{button}</PopoverButton>
			<PopoverPanel
				anchor={anchor}
				className={cn(
					"z-[200000] min-w-fit min-h-fit shadow-md shadow-gray-900/15 bg-transparent rounded-md",
					panelClassName,
				)}
			>
				{children}
			</PopoverPanel>
		</Popover>
	);
};

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { attachmentGroupMap } from "@inrev/common";
import { RankingInfo, rankItem, rankings } from "@tanstack/match-sorter-utils";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { HiChevronDoubleRight, HiChevronRight } from "react-icons/hi2";
import { useIsFirstRender } from "../../utils";
import { Modal } from "../layout/Modal";
import { cn } from "../lib/utils";
import { Checkbox } from "./Checkbox";
import { MultiSelectContent, MultiSelectSearch } from "./GroupMultiSelect";
import { RadioButton } from "./RadioButton";

export type AttachmentTypeMultiSelectProps<TFileType extends string> = {
	groupMap?: Map<string, Record<TFileType, string>>;
	availableTypes: TFileType[];
	initialTypes: TFileType[];
	title: string;
	selectOne?: boolean;
	expandedByDefault?: boolean;
	onSave: (types: TFileType[]) => void;
	onBlur?: () => void;
	close: () => void;
};

const onTypeSelect = <TFileType extends string>({
	item,
	value,
	selectOne,
	onChange,
}: {
	item: TFileType | Set<TFileType>;
	value: TFileType[];
	selectOne?: boolean;
	onChange: (value: TFileType[]) => void;
}) => {
	if (selectOne) {
		onChange([item as TFileType]);
	} else if (typeof item === "string") {
		onChange([...new Set([...value, item])]);
	} else {
		onChange([...new Set([...value, ...item])]);
	}
};

const onTypeDeselect = <TFileType extends string>({
	item,
	value,
	onChange,
}: {
	item: TFileType | Set<TFileType>;
	value: TFileType[];
	onChange: (value: TFileType[]) => void;
}) => {
	if (typeof item === "string") {
		onChange(value.filter((type) => type !== item));
	} else {
		onChange(value.filter((type) => !item.has(type)));
	}
};

const fuzzyMatchAndSort = <TFileType extends string>(
	searchString: string,
	groupMap: Map<string, Record<TFileType, string>>,
) => {
	const resultMap: typeof groupMap = new Map();
	let matchedGroups: [
		RankingInfo,
		RankingInfo,
		{ label: string; values: Record<string, string> },
		boolean,
	][] = [];
	let subTypes: [RankingInfo, TFileType, string][] = [];

	for (let [group, values] of groupMap) {
		const groupRanking = rankItem(group, searchString, {
			accessors: [
				{
					accessor: (data) => data,
					threshold: rankings.WORD_STARTS_WITH,
					maxRanking: rankings.EQUAL,
				},
			],
		});
		subTypes = [];
		for (const key in values) {
			if (Object.prototype.hasOwnProperty.call(values, key)) {
				const value = values[key as keyof typeof values];
				if (!value) continue;
				const ranking = rankItem(value, searchString, {
					accessors: [
						{
							accessor: (data) => data,
							threshold: rankings.WORD_STARTS_WITH,
							maxRanking: rankings.EQUAL,
						},
					],
				});
				if (ranking.passed || groupRanking.passed) {
					subTypes.push([ranking, key as TFileType, value]);
				}
			}
		}
		subTypes = subTypes.sort((a, b) => (a[0].rank > b[0].rank ? -1 : 1));
		if (subTypes.length) {
			const returnValues: Record<string, string> = {};
			subTypes.forEach(([, type, label]) => {
				Object.assign(returnValues, { [type]: label });
			});

			matchedGroups.push([
				groupRanking,
				subTypes[0][0],
				{
					label: group,
					values: returnValues,
				},
				false,
			]);
		}
	}
	matchedGroups = matchedGroups.sort((a, b) => {
		const aMax = Math.max(a[0].rank, a[1].rank);
		const bMax = Math.max(b[0].rank, b[1].rank);
		if (aMax > bMax || (aMax === bMax && a[3])) return -1;
		return 1;
	});
	matchedGroups.forEach((group) => {
		const items = group[2];
		resultMap.set(items.label, items.values);
	});
	return resultMap;
};

const AttachmentTypeSelectGroup = ({
	label,
	subsetSelected,
	hasFilters,
	numberOfSelected,
	groupCount,
	expandedByDefault,
	selectOne,
	children,
}: {
	label: string;
	subsetSelected: boolean;
	hasFilters: boolean;
	numberOfSelected: number;
	groupCount: number;
	expandedByDefault?: boolean;
	selectOne: boolean;
	children: ReactNode;
}) => {
	return (
		<Disclosure
			as="div"
			className="flex flex-col w-full min-h-fit relative"
			defaultOpen={subsetSelected || hasFilters || expandedByDefault}
		>
			<div className="sticky left-0 top-0 right-0 h-[48px] mb-[-48px] shadow-sm" />
			<DisclosureButton className="group/expand z-20 flex items-center h-[48px] space-x-[16px] sticky top-0 left-0 right-0 text-[14px] text-gray-600 font-semibold px-[25px] mb-[-1px] bg-white cursor-pointer">
				<div className="flex items-center h-full">
					<HiChevronRight className="text-[16px] stroke-[.75] text-gray-400 group-hover/expand:text-gray-800 ui-open:text-gray-800 ui-open:rotate-90" />
				</div>
				<div className="flex-1 flex items-center space-x-[10px] justify-between">
					<span>{label}</span>
					{numberOfSelected > 0 && !selectOne && (
						<span className="font-medium text-[13px] text-gray-500">
							{numberOfSelected}/{groupCount}
						</span>
					)}
				</div>
			</DisclosureButton>
			<DisclosurePanel className="relative flex-1 flex flex-col mt-[3px] mx-[20px]">
				<div className="h-[1px] bg-gray-200 mb-[8px]" />
				{children}
				<div className="absolute left-[-20px] right-[-20px] top-[-2px] h-[2px] bg-white" />
			</DisclosurePanel>
		</Disclosure>
	);
};

const AttachmentTypeSelectOption = ({
	label,
	selected,
	selectOne,
	onSelect,
	onDeselect,
	optionClassName,
}: {
	label: string;
	selected: boolean;
	selectOne?: boolean;
	onSelect: () => void;
	onDeselect: () => void;
	optionClassName?: string;
}) => {
	return (
		<div
			className={cn(
				"group flex items-center text-gray-800 w-full py-[10px] pl-[20px] pr-[10px] mb-[-1px] [&:nth-last-child(3)]:mb-0 [&:first-child]:border-t border-gray-200 space-x-[15px] hover:bg-gray-100 cursor-pointer",
				optionClassName,
			)}
			onClick={() => {
				selected ? onDeselect() : onSelect();
			}}
		>
			{selectOne ? <RadioButton checked={selected} /> : <Checkbox checked={selected} />}
			<div className="h-fit flex-1 text-[15px] text-gray-800 font">{label}</div>
		</div>
	);
};

const getGroupComponents = <TFileType extends string>({
	groupMap,
	availableTypes,
	selectedTypes,
	extraTypes,
	hasFilters,
	expandedByDefault,
	selectOne = false,
	onChange,
}: {
	groupMap: Map<string, Record<TFileType, string>>;
	availableTypes: TFileType[];
	extraTypes: Set<TFileType>;
	selectedTypes: TFileType[];
	hasFilters: boolean;
	expandedByDefault?: boolean;
	selectOne?: boolean;
	onChange: (value: TFileType[]) => void;
}) => {
	const groupComponents: ReactNode[] = [];
	let groupKey = 0;

	groupMap.forEach((group, groupName) => {
		if (
			(() => {
				if (typeof groupName === "string") {
					if (groupMap.has(groupName)) {
						return true;
					}
				}
				if (groupMap.has(groupName)) {
					return true;
				}
				return false;
			})()
		) {
			const subGroupComponents: ReactNode[] = [];
			let subGroupKey = 0;
			let selectedSubGroupCount = 0;
			// line item components

			availableTypes.forEach((subGroupAccessor) => {
				const label = group[subGroupAccessor as keyof typeof group];
				if (!group || !label) return;
				subGroupComponents.push(
					<AttachmentTypeSelectOption
						key={++subGroupKey}
						label={label}
						selected={(() => {
							if (selectedTypes.includes(subGroupAccessor)) {
								selectedSubGroupCount++;
								extraTypes.delete(subGroupAccessor);
								return true;
							}
							return false;
						})()}
						selectOne={selectOne}
						onSelect={() =>
							onTypeSelect({ item: subGroupAccessor, value: selectedTypes, onChange, selectOne })
						}
						onDeselect={() =>
							onTypeDeselect({ item: subGroupAccessor, value: selectedTypes, onChange })
						}
					/>,
				);
			});

			if (extraTypes.size > 0) {
				extraTypes.forEach((type) => {
					const label = group[type as keyof typeof group];
					if (!group || !label) return;
					subGroupComponents.push(
						<AttachmentTypeSelectOption
							key={++subGroupKey}
							label={label}
							selected={(() => {
								if (selectedTypes.includes(type)) {
									selectedSubGroupCount++;
									return true;
								}
								return false;
							})()}
							selectOne={selectOne}
							onSelect={() =>
								onTypeSelect({ item: type, value: selectedTypes, onChange, selectOne })
							}
							onDeselect={() => onTypeDeselect({ item: type, value: selectedTypes, onChange })}
						/>,
					);
				});
			}

			// group components for overall group
			if (subGroupComponents.length === 0) return;
			groupComponents.push(
				<AttachmentTypeSelectGroup
					key={++groupKey}
					label={groupName}
					hasFilters={hasFilters}
					numberOfSelected={selectedSubGroupCount}
					groupCount={subGroupComponents.length}
					subsetSelected={!!selectedSubGroupCount}
					expandedByDefault={expandedByDefault}
					selectOne={selectOne}
				>
					{subGroupComponents}
					<div className="w-full h-[12px] z-30 bg-white shrink-0"></div>
				</AttachmentTypeSelectGroup>,
			);
		}
	});
	return groupComponents;
};

const AttachmentTypeFilterListItem = ({
	label,
	selected,
	onClick,
}: {
	label: string;
	selected: boolean;
	onClick: () => void;
}) => (
	<div
		className={cn(
			selected
				? "flex items-center px-[12px] py-[8px] rounded cursor-pointer bg-inrev-blue text-[13px] font-medium text-gray-50 shadow-md"
				: "flex items-center px-[12px] py-[8px] rounded cursor-pointer bg-gray-50 hover:bg-gray-200/50 text-[13px] font-medium text-gray-600",
		)}
		onClick={onClick}
	>
		<span className="flex-1">{label}</span>
		<HiChevronDoubleRight className="text-[13px] stroke-[.75]" />
	</div>
);

export const AttachmentTypeMultiSelect = <TFileType extends string>({
	groupMap = attachmentGroupMap,
	availableTypes,
	title,
	initialTypes,
	expandedByDefault = false,
	selectOne,
	onSave,
	onBlur,
	close,
}: AttachmentTypeMultiSelectProps<TFileType>) => {
	const isFirstRender = useIsFirstRender();
	const [selectedSubsetFilterIndex, setSelectedSubsetFilterIndex] = useState<number | undefined>(0);
	const [searchValue, setSearchValue] = useState<string>("");
	const [selectedTypes, setSelectedTypes] = useState<TFileType[]>(initialTypes);
	const didValuesChange = useMemo(
		() =>
			<TFileType extends string>(initialValues: TFileType[], selectedTypes: TFileType[]) => {
				const changed =
					initialValues.length !== selectedTypes.length ||
					!initialValues.every((value) => selectedTypes.includes(value));
				return changed;
			},
		[initialTypes, selectedTypes],
	);

	const extraTypes = useMemo(() => {
		const types = new Set(initialTypes);
		availableTypes.forEach((type) => {
			if (types.has(type)) {
				types.delete(type);
			}
		});
		return types;
	}, [initialTypes, availableTypes]);

	const onChange = (newTypes: TFileType[]) => {
		setSelectedTypes(newTypes);
	};

	const handleSave = () => {
		if (didValuesChange(initialTypes, selectedTypes)) {
			onSave(selectedTypes);
			close();
		} else {
			close();
		}
	};

	const groupComponents = useMemo(() => {
		if (searchValue !== "" || !selectedSubsetFilterIndex) {
			return getGroupComponents({
				groupMap: fuzzyMatchAndSort(searchValue, groupMap),
				availableTypes,
				extraTypes,
				selectedTypes,
				hasFilters: !!searchValue,
				expandedByDefault,
				selectOne,
				onChange,
			});
		}
		return getGroupComponents({
			groupMap,
			availableTypes,
			extraTypes,
			hasFilters: !!searchValue,
			selectedTypes,
			expandedByDefault,
			selectOne,
			onChange,
		});
	}, [searchValue, groupMap, selectedSubsetFilterIndex, selectedTypes, availableTypes, onChange]);

	useEffect(() => {
		if (!isFirstRender && onBlur) {
			return onBlur();
		}
	}, []);

	return (
		<div className={cn("relative h-fit")}>
			<Modal onClickOutside={() => close()}>
				<div className="flex flex-col min-w-full h-[85vh] overflow-y-auto mt-[20px] text-gray-700 font-normal rounded-md bg-white shadow-lg z-[10] select-none w-[600px]">
					<MultiSelectSearch
						setSearchValue={setSearchValue}
						valuesChanged={didValuesChange(initialTypes, selectedTypes)}
						onSaveClick={handleSave}
						title={title}
						placeholder="Search for a file type"
					/>
					<MultiSelectContent groupComponents={groupComponents} hasFilters={false}>
						<AttachmentTypeFilterListItem
							label={"All"}
							selected={selectedSubsetFilterIndex === undefined}
							onClick={() => setSelectedSubsetFilterIndex(undefined)}
						/>
					</MultiSelectContent>
				</div>
			</Modal>
		</div>
	);
};

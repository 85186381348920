import { NoteId, NoteTopicId, Relations } from "@inrev/common";
import { AutoSaveTextarea } from "../../../../../../components/ui/AutoSaveTextarea";
import { useCreateAdminNote, useUpdateAdminNote } from "../../../../../../domain/admin/note/api";

// Handles both creating and updating notes
export const AdminNoteEditor = ({
	relation,
	topicId,
	content,
	currentNoteId,
	closeEditor,
	update = false,
}: {
	relation: Relations.Notes;
	topicId: NoteTopicId;
	content?: string;
	currentNoteId?: NoteId;
	update?: boolean;
	closeEditor: () => void;
}) => {
	const { createAdminNote } = useCreateAdminNote();
	const { updateAdminNote } = useUpdateAdminNote();

	const handleCreate = (noteContent: string) => {
		if (!relation) return;
		if (!update) {
			createAdminNote({
				content: noteContent,
				topicId,
				relation,
			});
		}
	};

	const handleUpdate = (noteContent: string) => {
		if (update && content !== noteContent) {
			if (!relation) return;
			updateAdminNote({
				data: {
					content: noteContent,
				},
				noteId: currentNoteId!,
				topicId,
				relation,
			});
		}
		closeEditor();
	};

	return (
		<AutoSaveTextarea
			content={content}
			update={update}
			closeEditor={closeEditor}
			createFn={handleCreate}
			updateFn={handleUpdate}
			placeholder="Enter a note..."
		/>
	);
};

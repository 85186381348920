import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../../components/ui/DataTable/DataTable";
import { Dropdown } from "../../../components/ui/Dropdown";
import { SearchInput } from "../../../components/ui/SearchInput";
import { useFetchAdminUsers } from "../../../domain/admin/admin-user/api";
import { SuretyAccountPreview } from "../../../domain/agent/account/types";
import { useFetchAccountPreviews } from "../../../domain/shared/previews/api";
import { useAuthenticatedUser } from "../../../providers/AuthenticatedUserProvider";
import { AdminTableLayout } from "../layout/AdminTableLayout";
import { AdminUserMenu } from "../settings/AdminUserMenu";
import { accountsTableColumns } from "./columns";

export const AdminAccountsView = () => {
	const navigate = useNavigate();
	const { user } = useAuthenticatedUser();
	const { accountPreviews } = useFetchAccountPreviews();
	const { adminUsers } = useFetchAdminUsers();
	const underwriterFilterOptions = useMemo(() => {
		return [
			{ value: user.id, label: "Me" },
			...(adminUsers ?? [])
				.filter((u) => u.id !== user.id)
				.map((u) => ({
					value: u.id,
					label: `${u.firstName} ${u.lastName}`,
				})),
		];
	}, [adminUsers, user]);
	const [statusFilterValue, setStatusFilterValue] = useState<string | undefined>(undefined);
	const [statusColumnFilter, setStatusColumnFilter] = useState<
		[{ id: "status"; value: string }] | []
	>([]);
	const [underwriterFilterValue, setUnderwriterFilterValue] = useState<string | undefined>(
		undefined,
	);
	const [underwriterColumnFilter, setUnderwriterColumnFilter] = useState<
		[{ id: "underwriter"; value: string }] | []
	>([]);
	const [searchString, setSearchString] = useState<string>("");

	useEffect(() => {
		if (statusFilterValue === undefined) {
			setStatusColumnFilter([]);
		} else setStatusColumnFilter([{ id: "status", value: statusFilterValue }]);
	}, [statusFilterValue]);

	useEffect(() => {
		if (underwriterFilterValue === undefined) {
			setUnderwriterColumnFilter([]);
		} else setUnderwriterColumnFilter([{ id: "underwriter", value: underwriterFilterValue }]);
	}, [underwriterFilterValue]);

	const handleRowClick = (row: SuretyAccountPreview) => {
		navigate(`/accounts/${row.id}`);
	};

	return (
		<AdminTableLayout
			userMenu={AdminUserMenu}
			title={
				<div className="w-full h-full">
					<div className="flex items-center h-full space-x-[25px]">
						<div className="leading-[23px]">Accounts</div>
						<div className="flex items-center space-x-[12px]">
							<SearchInput
								className="w-[250px] bg-gray-50 border border-gray-200 focus-within:outline-gray-400 focus-within:shadow-none"
								inputClassName="placeholder:text-gray-500"
								placeholder="Search accounts..."
								onChange={(value) => setSearchString(value)}
							/>
							<Dropdown
								value={statusFilterValue}
								placeholder="Status"
								options={[
									{ value: "review", label: "Review" },
									{ value: "approved", label: "Approved" },
									{ value: "declined", label: "Declined" },
									{ value: "active", label: "Active" },
									{ value: "closed", label: "Closed" },
								]}
								buttonClassName="gap-[3px] w-fit mb-[1px] h-[33px] py-[5px] pl-[12px] pr-[8px] text-[12px] font-medium text-gray-800 border outline-gray-200 bg-gray-50 bg-opacity-100 hover:brightness-[.98] data-[headlessui-state=open]:border-gray-500 data-[headlessui-state=open]:brightness-[.94] rounded hover:rounded-0"
								buttonIconClassName="text-[13px] text-gray-400"
								optionsClassName="divide-y divide-gray-100/80 shadow-[rgba(0,0,0,0.05)_0px_0px_4px,_rgba(0,0,0,0.08)_0px_2px_8px] outline-gray-500"
								optionClassName="text-[13px] font-medium py-[8px] text-gray-700 hover:bg-gray-100/60"
								onChange={(value) => {
									const val = value === statusFilterValue ? undefined : value;
									setStatusFilterValue(val);
								}}
							/>
							<Dropdown
								placeholder="Underwriter"
								value={underwriterFilterValue}
								options={underwriterFilterOptions}
								buttonClassName="gap-[3px] w-fit mb-[1px] h-[33px] py-[5px] pl-[12px] pr-[8px] text-[12px] font-medium text-gray-800 border outline-gray-200 bg-gray-50 bg-opacity-100 hover:brightness-[.98] data-[headlessui-state=open]:border-gray-500 data-[headlessui-state=open]:brightness-[.94] rounded hover:rounded-0"
								buttonIconClassName="text-[13px] text-gray-400"
								optionsClassName="divide-y divide-gray-100/80 shadow-[rgba(0,0,0,0.05)_0px_0px_4px,_rgba(0,0,0,0.08)_0px_2px_8px] outline-gray-500"
								optionClassName="text-[13px] font-medium py-[8px] text-gray-700 hover:bg-gray-100/60 whitespace-nowrap"
								onChange={(value) => {
									const val = value === underwriterFilterValue ? undefined : value;
									setUnderwriterFilterValue(val);
								}}
							/>
						</div>
					</div>
				</div>
			}
		>
			<div className="w-full h-full flex flex-col space-y-[8px]">
				<div className="w-full flex-1">
					<DataTable
						name="bond_requests"
						columnDefs={accountsTableColumns}
						onRowClick={handleRowClick}
						rowUrl={(row) => `/accounts/${row.id}`}
						columnFilters={[...statusColumnFilter, ...underwriterColumnFilter]}
						searchString={searchString}
						data={accountPreviews}
						loadingMessage="Loading accounts..."
					/>
				</div>
			</div>
		</AdminTableLayout>
	);
};

import type { Relations } from "@inrev/common";
import { useEffect, useMemo, useState } from "react";
import { CgMenuBoxed } from "react-icons/cg";
import { useSearchParams } from "react-router-dom";
import {
	ToolsExpandedLayout,
	ToolsLayout,
	ToolsSidebarLayout,
} from "../../../../components/layout/ToolsLayout";
import { AdminNotesTool } from "./notes/AdminNotesTool";

type AdminTool = keyof ReturnType<typeof buildAdminToolMap>;

const buildAdminToolMap = (relation: Relations.Notes) => ({
	notes: {
		icon: CgMenuBoxed,
		content: <AdminNotesTool relation={relation} />,
	},
});

export const AdminTools = ({ relation }: { relation: Relations.Notes }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const paramTool = useMemo(() => {
		return (searchParams.get("adminTool") as AdminTool) || undefined;
	}, [searchParams]);
	const [currentTool, setCurrentTool] = useState<AdminTool | undefined>(paramTool);
	const toolMap = useMemo(() => buildAdminToolMap(relation), [relation]);

	useEffect(() => {
		if (currentTool) {
			setSearchParams((prev) => {
				prev.set("adminTool", currentTool);
				return prev;
			});
		}
	}, [currentTool]);

	const collapseToolbar = () => {
		setCurrentTool(undefined);
		setSearchParams({});
	};

	return (
		<ToolsLayout
			sidebar={
				<ToolsSidebarLayout
					currentTool={currentTool}
					toolMap={toolMap}
					setCurrentTool={setCurrentTool}
				/>
			}
			expandedSidebar={
				<ToolsExpandedLayout
					currentTool={currentTool}
					close={collapseToolbar}
					setCurrentTool={setCurrentTool}
					toolMap={toolMap}
				/>
			}
		/>
	);
};

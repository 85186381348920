import { ReactNode } from "react";
import { HiChevronRight } from "react-icons/hi2";
import { IconType } from "react-icons/lib";
import { cn } from "../lib/utils";

export type ToolMap = Record<string, { icon: IconType; content: ReactNode }>;

export const ToolsLayout = ({
	sidebar,
	expandedSidebar,
}: { sidebar: ReactNode; expandedSidebar: ReactNode }) => {
	return (
		<div className="w-fit h-full">
			{sidebar}
			{expandedSidebar}
		</div>
	);
};

export const ToolsSidebarLayout = <TToolMap extends ToolMap>({
	toolMap,
	setCurrentTool,
	currentTool,
}: {
	toolMap: TToolMap;
	currentTool: keyof TToolMap | undefined;
	setCurrentTool: (toolKey: keyof TToolMap) => void;
}) => {
	if (currentTool !== undefined) return <></>;
	return (
		<div className="flex flex-col h-full w-[48px] bg-white items-center pt-5 border-l border-l-gray-100">
			{Object.keys(toolMap).map((toolKey, idx) => {
				const ToolIcon = toolMap[toolKey].icon;
				return (
					<ToolIcon
						key={idx}
						className={cn(
							"h-[19px] w-[19px] flex items-center justify-center cursor-pointer text-gray-400 hover:text-gray-800",
							currentTool && "text-gray-800 cursor-default",
						)}
						onClick={() => setCurrentTool(toolKey)}
					/>
				);
			})}
		</div>
	);
};

export const ToolsExpandedLayout = <TToolMap extends ToolMap>({
	toolMap,
	currentTool,
	close,
	setCurrentTool,
}: {
	toolMap: TToolMap;
	currentTool: keyof TToolMap | undefined;
	close: () => void;
	setCurrentTool: (toolKey: keyof TToolMap) => void;
}) => {
	if (!currentTool) return <></>;
	return (
		<div className="w-[360px] flex flex-col justify-start bg-white border-l-[1px] border-l-gray-100 h-full">
			<ToolsTopBar
				toolMap={toolMap}
				close={close}
				currentTool={currentTool}
				setCurrentTool={setCurrentTool}
			/>
			<div className="h-full block overflow-y-scroll">{toolMap[currentTool].content}</div>
		</div>
	);
};

const ToolsTopBar = <TToolMap extends ToolMap>({
	toolMap,
	currentTool,
	setCurrentTool,
	close,
}: {
	toolMap: TToolMap;
	currentTool: keyof TToolMap;
	setCurrentTool: (toolKey: keyof TToolMap) => void;
	close: () => void;
}) => {
	return (
		<div className="sticky top-0 bg-white z-30">
			<div className={cn("flex flex-row justify-between items-center w-full h-[40px]")}>
				<div>
					<div className="pl-5">
						{Object.keys(toolMap).map((toolKey, idx) => {
							const ToolIcon = toolMap[toolKey].icon;
							return (
								<ToolIcon
									key={idx}
									className={cn(
										"text-gray-700",
										!currentTool && "text-gray-400 hover:text-gray-800 cursor-pointer",
									)}
									onClick={() => {
										if (toolKey !== currentTool) {
											setCurrentTool(toolKey);
										}
									}}
								/>
							);
						})}
					</div>
				</div>
				<div
					className="flex items-center justify-center text-[12px] cursor-pointer text-gray-400 hover:text-gray-800 mr-4"
					onClick={close}
				>
					<HiChevronRight className="stroke-[.75]" />
				</div>
			</div>
		</div>
	);
};

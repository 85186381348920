import { z } from 'zod';
import { companyFinancialStatementPreparationMethodSchema } from '../../../enums.mjs';
import { financialStatementId, companyContactId, suretyAccountId } from '../../../types/opaque.mjs';
import { isoDateSchema, attachmentSchema, dateTimeToISODateTimeSchema, currencySchema } from '../../../types/utils.mjs';
import '../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../types/credit-report/company-credit-report.mjs';
import '../../../types/credit-report/individual-credit-report.mjs';
import '../../../types/payment/payment-template.mjs';
import '../../../types/surety/account/surety-account.types.mjs';
import '../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../types/surety/bond/surety-bond.types.mjs';
import '../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../types/user/user.types.mjs';
import '../../../types/entityRelation/entityRelation.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = z.object({
            id: financialStatementId,
            date: isoDateSchema,
            preparationMethod: companyFinancialStatementPreparationMethodSchema.optional(),
            corporateCash: z.number().optional(),
            currentAssets: z.number().optional(),
            currentLiabilities: z.number().optional(),
            totalAssets: z.number().optional(),
            totalLiabilities: z.number().optional(),
            accountsPayable: z.number().optional(),
            accountsReceivable: z.number().optional(),
            underbillings: z.number().optional(),
            creditCardsPayable: z.number().optional(),
            bankLinePayable: z.number().optional(),
            blocSize: z.number().optional(),
            accruedExpenses: z.number().optional(),
            overbillings: z.number().optional(),
            currentPortionOfLtd: z.number().optional(),
            termLoanDebt: z.number().optional(),
            revenue: z.number().optional(),
            gaExpense: z.number().optional(),
            profitable: z.boolean().optional(),
            calculations: z.object({
                workingCapital: z.number().optional(),
                equity: z.number().optional(),
                workingCapitalToBacklogCost: z.number().optional(),
                corporateCashToBacklogCost: z.number().optional(),
                equityToBacklogCost: z.number().optional(),
                corporateCashToTotalAssets: z.number().optional(),
                workingCapitalToTotalAssets: z.number().optional(),
                totalLiabilitiesToEquity: z.number().optional(),
                workingCapitalToCurrentAssets: z.number().optional(),
                blocAvailable: z.number().optional(),
                netCash: z.number().optional(),
                currentDebt: z.number().optional(),
                bankDebt: z.number().optional(),
                blocAvailability: z.number().optional(),
                netWorthToBacklogCost: z.number().optional(),
                netCashToTotalAssets: z.number().optional(),
                accountsReceivableTurnover: z.number().optional(),
                underbillingsToWorkingCapital: z.number().optional(),
                currentDebtToTotalAssets: z.number().optional(),
                bankDebtToTotalAssets: z.number().optional(),
                gaExpenseToEquity: z.number().optional(),
                backlogGrossProfitToGaExpense: z.number().optional(),
            }),
            companyContactId: z.string().uuid().optional(),
            suretyAccountId: z.string().uuid().optional(),
            attachments: z.array(attachmentSchema()),
            createdAt: dateTimeToISODateTimeSchema,
            updatedAt: dateTimeToISODateTimeSchema
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Create;
(function (Create) {
    (function (Request) {
        Request.baseSchema = z.object({
            date: isoDateSchema,
            preparationMethod: companyFinancialStatementPreparationMethodSchema,
            corporateCash: currencySchema,
            currentAssets: currencySchema,
            currentLiabilities: currencySchema,
            totalAssets: currencySchema,
            totalLiabilities: currencySchema,
            accountsPayable: currencySchema,
            accountsReceivable: currencySchema,
            underbillings: currencySchema,
            creditCardsPayable: currencySchema,
            bankLinePayable: currencySchema,
            blocSize: currencySchema,
            accruedExpenses: currencySchema,
            overbillings: currencySchema,
            currentPortionOfLtd: currencySchema,
            termLoanDebt: currencySchema,
            revenue: currencySchema,
            gaExpense: currencySchema,
            profitable: z.boolean(),
        });
        Request.schema = Request.baseSchema.and(z.object({ companyContactId: companyContactId }).or(z.object({ suretyAccountId: suretyAccountId })));
    })(Create.Request || (Create.Request = {}));
})(Create || (Create = {}));
var Update;
(function (Update) {
    (function (Request) {
        Request.schema = Create.Request.schema;
    })(Update.Request || (Update.Request = {}));
})(Update || (Update = {}));

export { Create, Get, Update };

import { z } from 'zod';
import '../admin-user.dto.mjs';
import '../agency/agency.dto.mjs';
import '../company-credit-report-preview.dto.mjs';
import '../company-credit-report.dto.mjs';
import '../financial-statement/admin-financial-statement.dto.mjs';
import '../individual-credit-report-preview.dto.mjs';
import '../individual-credit-report.dto.mjs';
import '../individual-ofac-check-preview.dto.mjs';
import '../individual-ofac-check.dto.mjs';
import '../surety/account/admin-surety-account.dto.mjs';
import '../surety/account/contact/company-contact.dto.mjs';
import '../surety/account/contact/individual-contact.dto.mjs';
import '../surety/agency/carrier-agency-relationship.dto.mjs';
import '../surety/bond-form/surety-bond-form.dto.mjs';
import '../surety/bond/admin-surety-bond.dto.mjs';
import '../surety/carrier-bonding-line/admin-surety-account-bonding-line.dto.mjs';
import '../surety/indemnity-agreement/surety-indemnity-agreement.dto.mjs';
import '../surety/indemnity-agreement/surety-indemnity-waiver.dto.mjs';
import '../surety/quote/surety-quote.dto.mjs';
import '../underwriter-assignment.dto.mjs';
import '../wip-statement/admin-wip-statement.dto.mjs';
import { Get as Get$1 } from './admin-note.dto.mjs';
import { noteTopicId } from '../../../types/opaque.mjs';
import { dateTimeToISODateSchema } from '../../../types/utils.mjs';
import '../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../types/credit-report/company-credit-report.mjs';
import '../../../types/credit-report/individual-credit-report.mjs';
import '../../../types/payment/payment-template.mjs';
import '../../../types/surety/account/surety-account.types.mjs';
import '../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../types/surety/bond/surety-bond.types.mjs';
import '../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../types/user/user.types.mjs';
import { Relations } from '../../../types/entityRelation/entityRelation.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = z.object({
            id: noteTopicId,
            name: z.string(),
            summary: z.string().default(""),
            default: z.boolean(),
            archived: z.boolean(),
            notes: z.array(Get$1.Response.schema),
            createdAt: dateTimeToISODateSchema,
            updatedAt: dateTimeToISODateSchema,
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Create;
(function (Create) {
    (function (Request) {
        Request.schema = Relations.Notes.schema.and(z.object({
            name: z.string(),
            summary: z.string().optional(),
        }));
    })(Create.Request || (Create.Request = {}));
})(Create || (Create = {}));
var Update;
(function (Update) {
    (function (Request) {
        Request.schema = z.object({
            name: z.string().optional(),
            summary: z.string().optional(),
            archived: z.boolean().optional(),
        });
    })(Update.Request || (Update.Request = {}));
})(Update || (Update = {}));

export { Create, Get, Update };

import { ContractSuretyType } from "@inrev/common";
import { useNavigate } from "react-router-dom";
import { Separator } from "../../../components/ui/Separator";
import { bondRequestTypeLabelMap } from "../../../constants";
import type { SubmittedBondRequest } from "../../../domain/agent/request/types";

export const BondRequestTitle = ({
	request,
	bidBond,
}: { request: SubmittedBondRequest; bidBond?: { number: string } }) => {
	const navigate = useNavigate();
	return (
		<div className="flex items-center space-x-[10px] cursor-default">
			<span>
				{bondRequestTypeLabelMap[request.contractSuretyType]} Bond Request
				{request.contractSuretyType === ContractSuretyType.bid_to_final &&
				bidBond?.number !== undefined
					? ` from Bid Bond ${bidBond?.number}`
					: ""}
			</span>
			<Separator orientation="vertical" className="bg-gray-300 h-[18px]" />
			<span
				className="text-gray-600 font-normal cursor-pointer hover:underline"
				onClick={() => navigate(`/accounts/${request.account.id}`)}
			>
				{request.principal.name}
			</span>
		</div>
	);
};

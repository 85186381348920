import { useMemo } from "react";
import {
	HiExclamationTriangle,
	HiOutlineBuildingOffice,
	HiOutlineBuildingOffice2,
	HiOutlineMapPin,
	HiOutlineUser,
} from "react-icons/hi2";
import { Separator } from "../../../../components/ui/Separator";
import { getStatusCardByStatus } from "../../../../components/ui/StatusCard";
import { AdminSuretyAccount } from "../../../../domain/admin/account/types";
import { getAccountPrimary } from "../../../../domain/admin/account/utils";
import { UseFollowersReturn } from "../../../../domain/shared/followers/types";
import { formatAddress } from "../../../../utils";
import { AdminAssigneeInlineControls } from "../../shared/AdminAssigneeInlineControls";
import { AdminFollowerControls } from "../../shared/AdminFollowerControls";
import { NAICSCodesList } from "../../shared/NAICSCodeList";

export type AdminAccountHeaderProps = {
	account: AdminSuretyAccount;
	actionRequired?: boolean;
	followersControl: UseFollowersReturn;
};

export const AdminAccountHeader = ({
	account,
	actionRequired,
	followersControl,
}: AdminAccountHeaderProps) => {
	const primary = useMemo(() => {
		const primary = getAccountPrimary(account);
		if (!primary) throw new Error();
		return primary;
	}, [account.primaryCompanyId, account.companies]);

	return (
		<>
			<div className="flex w-full space-x-[120px] mt-[35px]">
				<div className="flex flex-col flex-1 space-y-[18px]">
					<div className="flex items-start space-x-[15px]">
						{actionRequired ? (
							<HiExclamationTriangle className="text-[22px] fill-inrev-yellow mt-[7px]" />
						) : (
							<></>
						)}
						<HiOutlineBuildingOffice2 className="mt-[4px] text-[24px] text-gray-600" />
						<span className="text-gray-900 text-[25px] font-semibold leading-[32px]">
							{account.displayName}
						</span>
					</div>
					<div className="flex flex-col space-y-[10px] pl-[2px] text-[15px] font-normal text-gray-700 leading-[18px]">
						<div>Broker of Record: {account.agency.displayName}</div>
						<div className="flex items-center space-x-[8px]">
							<HiOutlineMapPin className="text-[16px] stroke-[1.75] text-gray-500" />
							<span>{formatAddress(primary.address)}</span>
						</div>
						<div className="flex items-center space-x-[12px]">
							<div className="flex items-center space-x-[8px]">
								<HiOutlineBuildingOffice className="text-[16px] stroke-[1.75] text-gray-500" />
								<span>
									{account.companies.length} Compan{account.companies.length === 1 ? "y" : "ies"}
								</span>
							</div>
							<Separator orientation="vertical" className="bg-gray-200" />
							<div className="flex items-center space-x-[8px]">
								<HiOutlineUser className="text-[16px] stroke-[2] text-gray-500" />
								<span>
									{account.individuals.length} Individual
									{account.individuals.length === 1 ? "" : "s"}
								</span>
							</div>
						</div>
						<div className="flex space-x-[15px]">
							<AdminAssigneeInlineControls
								assignedEntity={{ entityType: "account", entityId: account.id }}
								currentAssignee={account.underwriterUserId}
							/>
							<AdminFollowerControls
								{...followersControl}
								manageFollowersSubtext="Followers will receive email notifications for all comments and changes to the status of this account"
								agencyId={account.agency.id}
							/>
						</div>
						<NAICSCodesList
							naicsCodes={account.contract.naicsCodes}
							showRatio
							className="!mt-[16px]"
						/>
					</div>
				</div>
				<div className="flex flex-col space-y-[15px] w-[165px] max-w-[165px] pt-[5px]">
					{getStatusCardByStatus(account.status)}
				</div>
			</div>
		</>
	);
};

import { z } from 'zod';
import { usStateOrTerritorySchema } from '../../enums.mjs';
import { agencyId, npn, slackId } from '../opaque.mjs';

var Metadata;
(function (Metadata) {
    let Agent;
    (function (Agent) {
        Agent.schema = z.object({
            agencyId: agencyId,
            npn: npn.nullish(),
            residentLicenseState: usStateOrTerritorySchema.nullish(),
        });
    })(Agent = Metadata.Agent || (Metadata.Agent = {}));
    let Admin;
    (function (Admin) {
        Admin.schema = z.object({
            slackId: slackId.nullish(),
        });
    })(Admin = Metadata.Admin || (Metadata.Admin = {}));
    Metadata.schema = z.union([Agent.schema, Admin.schema]);
})(Metadata || (Metadata = {}));

export { Metadata };

"use client";
import { formatToDollar, formatToPercent } from "@inrev/common";
import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { getStatusTagByStatus } from "../../../components/ui/StatusTag";
import { RowActionButtons } from "../../../components/ui/row-action-buttons/RowActionButtons";
import { SuretyAccountPreview } from "../../../domain/agent/account/types";
import { formatEmpty } from "../../../utils";
import { AdminAssigneeControls } from "../shared/AdminAssigneeControls";

export const accountsTableColumns: ColumnDef<SuretyAccountPreview>[] = [
	{
		id: "underwriter",
		header: undefined,
		accessorKey: "underwriterUserId",
		cell: (props) => (
			<RowActionButtons alwaysShow={true}>
				<AdminAssigneeControls
					assignedEntity={{ entityType: "account", entityId: props.row.original.id }}
					currentAssignee={props.row.original.underwriterUserId!}
				/>
			</RowActionButtons>
		),
		meta: {
			noTruncate: true,
		},
		minSize: 60,
		size: 60,
		maxSize: 60,
		enableResizing: false,
		enableSorting: true,
		enableGlobalFilter: false,
		enableHiding: false,
		enableColumnFilter: true,
		enablePinning: false,
		enableGrouping: false,
		enableMultiSort: false,
	},
	{
		id: "status",
		accessorKey: "status",
		header: "Status",
		filterFn: "equalsString",
		cell: (props) => getStatusTagByStatus(props.row.original.status),
		meta: { noTruncate: true },
		size: 180,
		minSize: 130,
	},
	{
		id: "displayName",
		accessorKey: "displayName",
		accessorFn: ({ displayName }) => formatEmpty(displayName),
		header: "Name",
		size: 180,
		minSize: 130,
	},
	{
		id: "rate",
		accessorFn: ({ contract }) => contract?.bondingLine?.rate ?? 0,
		cell: (props) => formatEmpty(formatToPercent(props.row.original.contract?.bondingLine?.rate)),
		enableGlobalFilter: false,
		header: "Rate",
		size: 180,
		minSize: 130,
	},
	{
		id: "singleLimit",
		accessorFn: ({ contract }) => contract?.bondingLine?.singleLimit ?? 0,
		cell: (props) =>
			formatEmpty(formatToDollar(props.row.original.contract?.bondingLine?.singleLimit)),
		enableGlobalFilter: false,
		header: "Single Limit",
		size: 180,
		minSize: 130,
	},
	{
		id: "aggregateLimit",
		accessorFn: ({ contract }) => contract?.bondingLine?.aggregateLimit ?? 0,
		cell: (props) =>
			formatEmpty(formatToDollar(props.row.original.contract?.bondingLine?.aggregateLimit)),
		enableGlobalFilter: false,
		header: "Aggregate Limit",
		size: 180,
		minSize: 130,
	},
	{
		id: "totalExposure",
		accessorFn: ({ contract }) => contract?.exposure.total ?? 0,
		cell: (props) => formatEmpty(formatToDollar(props.row.original.contract?.exposure.total)),
		enableGlobalFilter: false,
		header: "Total Exposure",
		size: 180,
		minSize: 130,
	},
	{
		id: "submittedAt",
		accessorKey: "submittedAt",
		accessorFn: ({ submittedAt }) =>
			submittedAt !== undefined ? DateTime.fromISO(submittedAt).toJSDate() : undefined,
		cell: (props) =>
			props.row.original.submittedAt !== undefined
				? DateTime.fromISO(props.row.original.submittedAt).toLocaleString(DateTime.DATE_MED)
				: "--",
		enableGlobalFilter: false,
		header: "Submitted",
		size: 180,
		minSize: 130,
	},
];

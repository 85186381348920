import { z } from 'zod';
import { addressSchema, npn, userId, agencyId } from '../../../types/opaque.mjs';
import '../../../types/utils.mjs';
import '../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../types/credit-report/company-credit-report.mjs';
import '../../../types/credit-report/individual-credit-report.mjs';
import '../../../types/payment/payment-template.mjs';
import '../../../types/surety/account/surety-account.types.mjs';
import '../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../types/surety/bond/surety-bond.types.mjs';
import '../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../types/user/user.types.mjs';
import '../../../types/entityRelation/entityRelation.mjs';
import { agencyLicensedForSchema } from '../../../types/agency/agency.types.mjs';

var Create;
(function (Create) {
    (function (Request) {
        Request.schema = z.object({
            displayName: z.string(),
            legalName: z.string(),
            address: addressSchema,
            npn: npn,
            licensedFor: agencyLicensedForSchema,
            correspondenceEmailUsername: z.string(),
            docusignBondsUserId: z.string().nullish(),
            logoUrl: z.string(),
            logoSmallUrl: z.string(),
            buttonColorHex: z.string(),
            buttonTextColorHex: z.string(),
            underwriterUserId: userId,
        });
    })(Create.Request || (Create.Request = {}));
})(Create || (Create = {}));
var Update;
(function (Update) {
    (function (Request) {
        Request.schema = Create.Request.schema;
    })(Update.Request || (Update.Request = {}));
})(Update || (Update = {}));
var Get;
(function (Get) {
    (function (Preview) {
        (function (Response) {
            Response.schema = z.object({
                id: agencyId,
                displayName: z.string(),
                underwriterUserId: userId.nullish(),
            });
        })(Preview.Response || (Preview.Response = {}));
    })(Get.Preview || (Get.Preview = {}));
    (function (Response) {
        Response.schema = z.object({
            id: agencyId,
            displayName: z.string(),
            legalName: z.string(),
            address: addressSchema,
            npn: npn,
            underwriterUserId: userId.nullish(),
            licensedFor: agencyLicensedForSchema,
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Create, Get, Update };

import { ComponentType, ReactNode } from "react";
import { UserMenuProps } from "./UserMenuLayout";

type IslandLayoutProps = {
	userMenu: ComponentType<UserMenuProps>;
	children?: ReactNode;
};

export const IslandLayout = (props: IslandLayoutProps) => {
	const { children } = props;
	return (
		<div className="w-full h-full grid grid-rows-[45px_1fr]">
			<div className="w-full h-[45px] flex items-center justify-end px-[30px]">
				<props.userMenu />
			</div>
			<div className="w-full flex-1 flex justify-center overflow-y-auto">{children}</div>
		</div>
	);
};

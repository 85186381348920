import { Listbox, ListboxProps, Transition } from "@headlessui/react";
import { ElementType, Fragment, forwardRef, useMemo } from "react";
import { FieldError } from "react-hook-form";
import { HiCheck, HiChevronDown } from "react-icons/hi2";
import { FormError } from "../layout/form/FormError";
import { cn } from "../lib/utils";

export type DropdownProps<
	TTag extends ElementType = "div",
	TType = string,
	TActualType = TType extends (infer U)[] ? U : TType,
> = ListboxProps<TTag, TType, TActualType> & {
	buttonClassName?: string;
	buttonIconClassName?: string;
	optionsClassName?: string;
	optionClassName?: string;
	optionIconClassName?: string;
	placeholder?: string;
	condensed?: boolean;
	options: {
		label: string;
		value: string;
		className?: string;
	}[];
	error?: FieldError;
	errorMessage?: string;
	readonly?: boolean;
	className?: string;
};

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
	(
		{
			buttonClassName,
			buttonIconClassName,
			optionsClassName,
			optionClassName,
			optionIconClassName,
			placeholder,
			condensed,
			options,
			error,
			errorMessage,
			disabled,
			readonly,
			className,
			...props
		},
		_ref,
	) => {
		const selectedOption = useMemo(() => {
			if (props.value !== null) {
				return options.find((option) => option.value === props.value);
			}
			return props.value;
		}, [props.value]);

		return (
			<Listbox
				{...props}
				as="div"
				className={cn("relative h-fit", className)}
				disabled={options.length === 0 || disabled || readonly}
			>
				<Listbox.Button
					type="button"
					tabIndex={0}
					className={cn(
						"group flex items-center w-full text-left space-x-[3px] px-[18px] h-[50px] text-[16.5px] text-gray-900 cursor-pointer rounded-md bg-gray-50 bg-opacity-70 outline outline-[1px] outline-offset-[-1px] outline-gray-300 leading-[20px] focus-visible:outline-gray-600 focus-visible:hover:outline-gray-600 focus-visible:outline-[2px] data-[headlessui-state=open]:outline-gray-600 disabled:text-gray-800 disabled:opacity-80 disabled:bg-gray-100 disabled:outline-gray-200 disabled:cursor-default data-[headlessui-state=open]:outline-[2px]",
						disabled ? "cursor-not-allowed opacity-50" : "hover:outline-gray-500",
						condensed ? "h-[46px] max-h-[46px] text-[16px]" : undefined,
						buttonClassName,
						error
							? "outline-red-500 hover:outline-red-500 focus-visible:outline-red-500 data-[headlessui-state=open]:outline-red-500"
							: undefined,
					)}
				>
					{selectedOption ? (
						<div className="flex-1">{selectedOption?.label}</div>
					) : (
						<div className="flex-1 text-gray-600 group-disabled:text-gray-400">{placeholder}</div>
					)}
					<HiChevronDown
						className={cn(
							"text-[16px] stroke-[.75] text-gray-400 group-disabled:opacity-70",
							buttonIconClassName,
						)}
					/>
				</Listbox.Button>
				<Transition
					as={Fragment}
					leave="transition ease-in duration-75"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Listbox.Options
						className={cn(
							"absolute overflow-y-auto w-fit min-w-full px-0 mt-1 text-gray-700 font-normal rounded bg-white shadow-lg z-[500] cursor-pointer select-none outline outline-[1px] outline-gray-100 outline-offset-[-1px]",
							optionsClassName,
						)}
					>
						<div className="w-full h-fit">
							{options.map((option, index) => (
								<Listbox.Option
									key={index}
									className={({ active }) =>
										cn(
											`flex items-center text-gray-800 w-full py-[6px] pl-[15px] pr-[10px] space-x-[10px] ${active ? "bg-gray-100" : ""}`,
											optionClassName,
											option.className,
										)
									}
									value={option.value}
								>
									{() => (
										<>
											<div className="h-fit flex-1">{option.label}</div>
											{props.value === option.value ? (
												<HiCheck
													className={cn("text-inrev-gray-600 text-[16px]", optionIconClassName)}
												/>
											) : (
												<div className="w-[16px] h-[16px]"></div>
											)}
										</>
									)}
								</Listbox.Option>
							))}
						</div>
					</Listbox.Options>
				</Transition>
				<FormError error={error} errorMessage={errorMessage} />
			</Listbox>
		);
	},
);

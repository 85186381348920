import { HiDotsVertical } from "react-icons/hi";
import { Menu } from "../../../../../../components/ui/Menu";

export const AdminNoteMenu = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	return (
		<Menu
			button={<HiDotsVertical />}
			anchor="left"
			buttonClassName="h-[14px] w-[14px] cursor-pointer text-gray-400 hover:text-gray-800"
			panelClassName="flex flex-col items-start mt-6 w-[81px] bg-white border border-slate-100 rounded-md shadow-lg"
		>
			{children}
		</Menu>
	);
};

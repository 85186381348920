import { ConfirmationModalClickWrapper } from "../../../components/layout/ConfirmationModalClickWrapper";
import { ArchiveRowActionButton } from "../../../components/ui/row-action-buttons/ArchiveRowActionButton";
import { DeleteRowActionButton } from "../../../components/ui/row-action-buttons/DeleteRowActionButton";
import { UnrchiveRowActionButton } from "../../../components/ui/row-action-buttons/UnarchiveRowActionButton";
import {
	useArchiveAccount,
	useDeleteAccountDraft,
	useUnarchiveAccount,
} from "../../../domain/agent/account/api";
import { SuretyAccountPreview } from "../../../domain/agent/account/types";

type AccountsActionButtonsProps = {
	account: SuretyAccountPreview;
};

export const AccountsActionButtons = ({ account }: AccountsActionButtonsProps) => {
	const { deleteAccountDraft } = useDeleteAccountDraft();
	const { archiveAccount } = useArchiveAccount();
	const { unarchiveAccount } = useUnarchiveAccount();

	if (account.status === "draft") {
		return (
			<ConfirmationModalClickWrapper
				message="Are you sure you want to delete this account draft?"
				confirmButtonText="Yes, Delete"
				confirmationButtonClassName="border-[1px] border-red-500 bg-white text-red-500 hover:bg-red-500 hover:bg-opacity-10"
				onConfirm={() => deleteAccountDraft(account.id)}
			>
				<DeleteRowActionButton />
			</ConfirmationModalClickWrapper>
		);
	}
	if (account.archived) {
		return (
			<ConfirmationModalClickWrapper
				message="Are you sure you want to unarchive this request?"
				confirmButtonText="Yes, Unarchive"
				onConfirm={() => unarchiveAccount(account.id)}
			>
				<UnrchiveRowActionButton />
			</ConfirmationModalClickWrapper>
		);
	}
	return (
		<ConfirmationModalClickWrapper
			message="Are you sure you want to archive this account?"
			confirmButtonText="Yes, Archive"
			onConfirm={() => archiveAccount(account.id)}
		>
			<ArchiveRowActionButton />
		</ConfirmationModalClickWrapper>
	);
};

import { Relations } from "@inrev/common";
import { useState } from "react";
import { ConfirmationModal } from "../../../../../../components/layout/ConfirmationModal";
import type { AdminNoteTopic } from "../../../../../../domain/admin/note/types";
import { AdminNotesTopicHeader } from "../components/AdminNotesTopicHeader";
import { AdminNotesLayout } from "../layouts/AdminNotesLayout";
import { AdminNotesNotesSection } from "../shared/AdminNotesNotesSection";
import { AdminNoteSummarySection } from "../shared/AdminNotesSummarySection";

export const AdminNotesArchivedTopicView = ({
	relation,
	archivedTopic,
	notesList,
	onBack,
	onUnarchive,
}: {
	relation: Relations.Notes;
	archivedTopic: AdminNoteTopic;
	notesList: React.ReactNode;
	onBack: () => void;
	onUnarchive?: () => void;
}) => {
	const [confirmationOpen, setConfirmationOpen] = useState(false);

	const header = (
		<>
			<AdminNotesTopicHeader
				title={archivedTopic.name}
				onBack={onBack}
				actionButton={
					onUnarchive && (
						<div
							className="flex items-center justify-center cursor-pointer text-gray-400 hover:text-gray-700 text-[13px]"
							onClick={() => setConfirmationOpen(true)}
						>
							Unarchive
						</div>
					)
				}
			/>
			{confirmationOpen && onUnarchive && (
				<ConfirmationModal
					message="Are you sure you want to unarchive this topic?"
					cancelButtonText="Cancel"
					confirmButtonText="Unarchive"
					onCancel={() => setConfirmationOpen(false)}
					onConfirm={() => {
						onUnarchive();
						setConfirmationOpen(false);
					}}
				/>
			)}
		</>
	);

	const content = (
		<>
			<AdminNoteSummarySection
				topicId={archivedTopic.id}
				topicSummary={archivedTopic.summary}
				relation={relation}
				allowUpdate={false}
			/>
			<AdminNotesNotesSection
				relation={relation}
				topicId={archivedTopic.id}
				allowCreate={false}
				notesList={notesList}
			/>
		</>
	);

	return <AdminNotesLayout header={header} content={content} />;
};

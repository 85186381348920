import { Relations } from "@inrev/common";
import type { AdminNoteTopic } from "../../../../../../domain/admin/note/types";
import { AdminNotesLayout } from "../layouts/AdminNotesLayout";
import { AdminNotesNotesSection } from "../shared/AdminNotesNotesSection";
import { AdminNotesTopicsSection } from "../shared/AdminNotesTopicsSection";

export const AdminNotesArchivedDefaultView = ({
	relation,
	defaultArchivedTopic,
	archivedTopics,
	notesList,
	setCurrentTopic,
}: {
	relation: Relations.Notes;
	defaultArchivedTopic: AdminNoteTopic;
	archivedTopics: AdminNoteTopic[];
	notesList: React.ReactNode;
	setCurrentTopic: (topic: AdminNoteTopic) => void;
}) => {
	return (
		<AdminNotesLayout
			content={
				<>
					{archivedTopics.length > 0 && (
						<AdminNotesTopicsSection
							relation={relation}
							topics={archivedTopics}
							allowCreate={false}
							setCurrentTopic={setCurrentTopic}
						/>
					)}
					{defaultArchivedTopic.notes.length > 0 && (
						<AdminNotesNotesSection
							relation={relation}
							topicId={defaultArchivedTopic.id}
							allowCreate={false}
							notesList={notesList}
						/>
					)}
				</>
			}
		/>
	);
};

import { useAuth0 } from "@auth0/auth0-react";
import { AgencyId, Dtos, Email, FirstName, LastName, UserId, UserType } from "@inrev/common";
import { type ReactNode, createContext, useContext, useEffect, useState } from "react";
import { match } from "ts-pattern";
import { useRequest } from "../utils/request";
import { AppLoading } from "../views/shared/AppLoading";

export type AuthenticatedAdminUser = {
	type: typeof UserType.admin;
	id: UserId;
	role: string;
	setupComplete: boolean;
	firstName: FirstName;
	lastName: LastName;
	email: Email;
};
export type AuthenticatedAgentUser = {
	type: typeof UserType.agent;
	id: UserId;
	agencyId: AgencyId;
	role: string;
	setupComplete: boolean;
	firstName: FirstName;
	lastName: LastName;
	email: Email;
};
export type AuthenticatedUser = AuthenticatedAdminUser | AuthenticatedAgentUser;

export type AuthenticatedUserContext = {
	user: AuthenticatedUser;
	setAuthenticatedUser: (user: AuthenticatedUser) => void;
};

const AuthenticatedUserContext = createContext<AuthenticatedUserContext | null>(null);

export const useAuthenticatedUser = () =>
	useContext(AuthenticatedUserContext) as AuthenticatedUserContext;

export const AuthenticatedUserProvider = ({ children }: { children: ReactNode }) => {
	const { get } = useRequest();
	const [user, setUser] = useState<AuthenticatedUser | undefined>();
	const { isLoading, isAuthenticated, loginWithRedirect, user: auth0User } = useAuth0();

	useEffect(() => {
		if (!isLoading) {
			if (isAuthenticated) {
				if (auth0User !== undefined) {
					get<Dtos.User.Get.Response>(`/v2/users/appData`).then((response) => {
						setUser(
							match(response)
								.with({ type: "agent", setupComplete: true }, (user) => ({
									...user,
									type: UserType.agent,
									agencyId: user.agencyId,
									id: user.id,
									role: user.roles[0],
									setupComplete: user.setupComplete,
									firstName: user.firstName,
									lastName: user.lastName,
									email: user.email,
								}))
								.with({ type: "agent" }, (inRevUser) => ({
									...user,
									type: UserType.agent,
									agencyId: inRevUser.agencyId,
									id: inRevUser.id,
									role: inRevUser.roles[0],
									setupComplete: inRevUser.setupComplete,
									firstName: "" as FirstName,
									lastName: "" as LastName,
									email: inRevUser.email,
								}))
								.with({ type: "admin" }, (inRevUser) => ({
									...user,
									type: UserType.admin,
									id: inRevUser.id,
									role: inRevUser.roles[0],
									setupComplete: inRevUser.setupComplete,
									firstName: inRevUser.firstName,
									lastName: inRevUser.lastName,
									email: inRevUser.email,
								}))
								.run(),
						);
					});
				}
			} else {
				loginWithRedirect();
			}
		}
	}, [isLoading]);

	if (isLoading || !isAuthenticated || user === undefined) {
		return <AppLoading />;
	}

	return (
		<AuthenticatedUserContext.Provider value={{ user, setAuthenticatedUser: setUser }}>
			{children}
		</AuthenticatedUserContext.Provider>
	);
};

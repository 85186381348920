import { Transition } from "@headlessui/react";
import {
	AttachmentFile,
	ContractSuretyType,
	SuretyQuoteStatus,
	defaultAttachmentTypeLabelMap,
} from "@inrev/common";
import { ReactNode, useState } from "react";
import { AttachmentsSection } from "../../../components/layout/AttachmentsSection";
import { CommentsSection } from "../../../components/layout/CommentsSection";
import { useFetchBond } from "../../../domain/agent/bond/api";
import { BondRequest } from "../../../domain/agent/request/types";
import { useComments } from "../../../domain/shared/comments/api";
import { AgentWorkflowLayout } from "../../agent/layout/AgentWorkflowLayout";
import { BondRequestCardHeader } from "./BondRequestCardHeader";
import { BondRequestCardLayout } from "./BondRequestCardLayout";
import { BondRequestTermsAndConditions } from "./BondRequestTermsAndConditions";
import { BondRequestTitle } from "./BondRequestTitle";

type SubmittedBondRequestLayoutProps = {
	request: BondRequest & Exclude<BondRequest, { status: "draft" | "bound" }>;
	banner?: ReactNode;
	actionButtons?: ReactNode;
	termsAndConditionsOpen: boolean;
	cardBottom?: ReactNode;
	children?: ReactNode;
};

export const SubmittedBondRequestLayout = ({
	request,
	banner,
	actionButtons,
	termsAndConditionsOpen,
	cardBottom,
	children,
}: SubmittedBondRequestLayoutProps) => {
	const [attachments, setAttachments] = useState<AttachmentFile[]>(request.attachments);
	const { comments, commentsLoading, createComment, createCommentLoading } = useComments(
		"quotes",
		request.id,
	);
	const { bond: bidBond } = useFetchBond(
		(() => {
			if (request.contractSuretyType === ContractSuretyType.bid_to_final) return request.bidBondId;
			return "";
		})(),
		request.contractSuretyType === ContractSuretyType.bid_to_final,
	);

	return (
		<AgentWorkflowLayout
			title={<BondRequestTitle request={request} bidBond={bidBond} />}
			contentClassName="bg-gray-50 bg-opacity-[.35]"
		>
			<div className="flex justify-center w-full h-full overflow-y-auto pt-[18px]">
				<div className="w-[725px] max-w-[725px] h-fit flex flex-col items-center space-y-[52px] pb-[125px]">
					<BondRequestCardLayout banner={banner}>
						<BondRequestCardHeader request={request} actionButtons={actionButtons} />
						{request.status !== SuretyQuoteStatus.declined &&
							request.status !== SuretyQuoteStatus.review && (
								<Transition
									show={termsAndConditionsOpen}
									as="div"
									enter="transition-all ease-in duration-[90ms]"
									enterFrom="h-0 opacity-50"
									enterTo="h-fit h-min-fit opacity-100"
									leave="transition-all ease-in duration-75"
									leaveFrom="opacity-0 h-fit min-h-fit"
									leaveTo="opacity-0 h-0"
								>
									<BondRequestTermsAndConditions request={request} />
								</Transition>
							)}
						{cardBottom}
					</BondRequestCardLayout>
					{children}
					<CommentsSection
						comments={comments}
						commentsLoading={commentsLoading}
						createComment={createComment}
						createCommentLoading={createCommentLoading}
						commentDownloadHeader={`${request.displayTitle} For ${request.principal.name}`}
					/>
					<AttachmentsSection
						attachments={attachments}
						downloadAllName={`${request.account.displayName} ${request.displayTitle} Attachments`}
						typeLabelMap={defaultAttachmentTypeLabelMap}
						preventDelete
						upload={{
							url: `/v2/surety/quotes/${request.id}/attachments`,
							onChange: (val) => setAttachments(val),
							allowedTypesAndLabels: defaultAttachmentTypeLabelMap,
							invalidateQueryKeys: [
								["bondRequests", request.id],
								["suretyAccounts", request.account.id],
							],
						}}
						download={{
							baseUrl: `/v2/surety/quotes/${request.id}/attachments`,
							baseQueryKey: "bondRequestAttachments",
						}}
					/>
				</div>
			</div>
		</AgentWorkflowLayout>
	);
};

import { Dtos } from "@inrev/common";
import { IslandLayout } from "../../../components/layout/IslandLayout";
import { Card } from "../../../components/ui/Card";
import {
	useAdminReassignUnderwriter,
	useFetchAdminUsers,
} from "../../../domain/admin/admin-user/api";
import { useAdminFetchAgencyPreviews } from "../../../domain/admin/agency/api";
import { AdminUserSelect } from "../shared/AdminUserSelect";
import { AdminUserMenu } from "./AdminUserMenu";

export const AgencyAssignmentsView = () => {
	const { agencyPreviews, agencyPreviewsLoading } = useAdminFetchAgencyPreviews();
	const { adminUsers, adminUsersLoading } = useFetchAdminUsers();
	const { reassignUnderwriter, reassignUnderwriterLoading } = useAdminReassignUnderwriter();

	return (
		<IslandLayout userMenu={AdminUserMenu}>
			<div className="flex flex-col gap-[30px] w-[1000px] max-w-[1000px] h-fit flex-flex-col mt-[25px] mx-[40px] pb-[75px]">
				<div className="flex w-full justify-between gap-[25px]">
					<span className="text-[26px] text-gray-800 font-semibold">Agency Assignments</span>
				</div>
				<div className="w-full flex flex-col">
					<Card className="flex items-center bg-gray-100 w-full gap-[12px] px-[12px] py-[5px] border-gray-400 text-[14px] font-medium text-gray-700">
						<div className="flex-[3] truncate">Agency</div>
						<div className="flex-[2] truncate">Underwriter</div>
					</Card>
					{!!agencyPreviews && !agencyPreviewsLoading && !!adminUsers && !adminUsersLoading && (
						<div className="w-full relative h-fit flex flex-col text-gray-800">
							{agencyPreviews.map((agency) => {
								return (
									<div
										key={agency.id}
										className="flex items-center gap-[12px] w-full px-[12px] py-[18px] border-b border-b-gray-200/80"
									>
										<div className="flex-[3] truncate">{agency.displayName}</div>
										<div className="flex-[2] h-[50px] truncate">
											<AdminUserSelect
												entity={{ entityType: "agency", entityId: agency.id }}
												currentAssignee={agency.underwriterUserId!}
												adminUsers={adminUsers}
												onChange={(value) => {
													const dto: Dtos.Admin.UnderwriterAssignment.Assign.Request = {
														entityType: "agency",
														entityId: agency.id,
														underwriterUserId: value,
													};
													reassignUnderwriter(dto);
												}}
												disabled={reassignUnderwriterLoading}
												className={"absolute"}
											/>
										</div>
									</div>
								);
							})}
						</div>
					)}
					{(agencyPreviewsLoading || !!agencyPreviews || adminUsersLoading || !!adminUsers) && (
						<div className="w-full px-[12px] py-[18px]">
							<div className="w-full h-[30px] bg-gray-100 animate-pulse rounded-md"></div>
						</div>
					)}
				</div>
			</div>
		</IslandLayout>
	);
};

import { Relations } from "@inrev/common";
import { useState } from "react";
import { HiPlus } from "react-icons/hi";
import { AutoSaveInput } from "../../../../../../components/ui/AutoSaveInput";
import { useCreateAdminNoteTopic } from "../../../../../../domain/admin/note/api";
import { AdminNoteTopic } from "../../../../../../domain/admin/note/types";

export const AdminNotesTopicsSection = ({
	relation,
	topics,
	allowCreate,
	setCurrentTopic,
}: {
	relation: Relations.Notes;
	topics: AdminNoteTopic[];
	allowCreate: boolean;
	setCurrentTopic: (topic: AdminNoteTopic) => void;
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const { createAdminNoteTopic } = useCreateAdminNoteTopic();

	const createFn = (topicTitle: string) => {
		if (!relation) return;
		createAdminNoteTopic({ name: topicTitle, ...relation });
	};

	return (
		<div className="flex flex-col gap-[16px]">
			<div className="flex flex-row justify-between items-center w-full">
				<p className="text-gray-400 font-[425] text-[12px]">Topics</p>
				{allowCreate && (
					<div
						className="text-[12px] flex items-center justify-center cursor-pointer text-gray-400 hover:text-gray-800"
						onClick={() => setIsEditing(true)}
					>
						<HiPlus />
					</div>
				)}
			</div>
			<div>
				{isEditing && (
					<AutoSaveInput
						content=""
						closeEditor={() => setIsEditing(false)}
						createFn={createFn}
						placeholder="Enter a topic..."
					/>
				)}
				{topics.length > 0 && (
					<div className="flex flex-col gap-y-[3px]">
						{topics.map((topic) => {
							return (
								<div key={topic.id} onClick={() => setCurrentTopic(topic)}>
									<div className="text-gray-600 font-[425] text-[14px] underline decoration-dotted decoration-gray-400 underline-offset-4 hover:text-gray-800 cursor-pointer">
										{topic.name}
									</div>
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};

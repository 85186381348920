import { PopoverButton } from "@headlessui/react";
import { cn } from "../../../../../../components/lib/utils";

export const PinMenuItem = ({
	isPinned,
	pinNote,
	disabled,
}: {
	isPinned: boolean;
	pinNote: () => void;
	disabled?: boolean;
}) => {
	return (
		<PopoverButton
			className={cn(
				"flex justify-start p-[10px] cursor-pointer hover:bg-gray-50 text-[12px] w-full",
				disabled && "text-gray-300 cursor-default hover:text-gray-300",
			)}
			onClick={() => {
				if (!disabled) {
					pinNote();
				}
			}}
		>
			{isPinned ? "Unpin" : "Pin"}
		</PopoverButton>
	);
};

export const ArchiveMenuItem = ({
	isArchived,
	archiveNote,
	disabled,
}: {
	isArchived: boolean;
	archiveNote: () => void;
	disabled?: boolean;
}) => {
	return (
		<PopoverButton
			className={cn(
				"flex justify-start p-[10px] cursor-pointer hover:bg-gray-50 text-[12px] w-full",
				disabled && "text-gray-300 cursor-default hover:text-gray-300",
			)}
			onClick={() => {
				if (!disabled) {
					archiveNote();
				}
			}}
		>
			{isArchived ? "Unarchive" : "Archive"}
		</PopoverButton>
	);
};

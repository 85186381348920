import { HiCheck, HiOutlineArchiveBox } from "react-icons/hi2";
import { ConfirmationModalClickWrapper } from "../../../../../components/layout/ConfirmationModalClickWrapper";
import { LoadingModal } from "../../../../../components/layout/LoadingModal";
import { ArchivedBanner } from "../../../../../components/ui/Banner";
import { Button } from "../../../../../components/ui/Button";
import { MoreActionsMenu } from "../../../../../components/ui/MoreActionsMenu";
import {
	useAcceptBondRequestTerms,
	useArchiveRequest,
	useUnarchiveRequest,
} from "../../../../../domain/agent/request/api";
import { BondRequest } from "../../../../../domain/agent/request/types";
import { SubmittedBondRequestLayout } from "../../../../shared/request/SubmittedBondRequestLayout";

type RejectedBondRequestViewProps = {
	request: BondRequest & Extract<BondRequest, { status: "rejected" }>;
};

export const RejectedBondRequestView = ({ request }: RejectedBondRequestViewProps) => {
	const { acceptBondRequestTerms, acceptBondRequestTermsIsLoading } = useAcceptBondRequestTerms(
		request.id,
	);
	const { archiveRequest } = useArchiveRequest();
	const { unarchiveRequest } = useUnarchiveRequest();

	return (
		<SubmittedBondRequestLayout
			request={request}
			banner={
				request.archived ? (
					<ArchivedBanner onUnarchive={() => unarchiveRequest(request.id)} />
				) : undefined
			}
			termsAndConditionsOpen
			actionButtons={
				<div className="flex items-center flex-col space-y-[4px] select-none">
					<ConfirmationModalClickWrapper
						className="items-center"
						message="Are you sure you want to accept the terms?"
						onConfirm={acceptBondRequestTerms}
					>
						<Button
							color="light-blue"
							rounded
							filled
							className="w-full flex items-center gap-x-[8px]"
						>
							<HiCheck className="text-[14px] stroke-[1.1]" />
							<span>Accept Terms</span>
						</Button>
					</ConfirmationModalClickWrapper>
					{!request.archived && (
						<MoreActionsMenu
							items={[
								{
									label: "Archive",
									icon: <HiOutlineArchiveBox className="text-[15px]" />,
									onClick: () => {
										archiveRequest(request.id);
									},
								},
							]}
							panelClassName="mt-[10px]"
						/>
					)}
				</div>
			}
		>
			{acceptBondRequestTermsIsLoading && <LoadingModal />}
		</SubmittedBondRequestLayout>
	);
};

import { HiMiniPuzzlePiece } from "react-icons/hi2";
import { NavLink } from "react-router-dom";
import { UserMenuLayout } from "../../../components/layout/UserMenuLayout";
import { useAuthenticatedUser } from "../../../providers/AuthenticatedUserProvider";

export const AdminUserMenu = () => {
	const { user } = useAuthenticatedUser();
	return (
		<UserMenuLayout
			user={user}
			logoutOptions={{
				openUrl: (url: string) => {
					window.open(url, "_self");
				},
			}}
		>
			<NavLink to="/agency-assignments">
				<div className="flex space-x-[10px] items-center text-[14px] w-full min-w-[200px] font-medium px-[20px] py-[12px] text-gray-800 hover:underline">
					<HiMiniPuzzlePiece className="text-[16px] text-gray-600 stroke-[1.75]" />
					<span>Agency Assignments</span>
				</div>
			</NavLink>
		</UserMenuLayout>
	);
};

import { Types, UserId } from "@inrev/common";
import { AdminAssigneeControls } from "./AdminAssigneeControls";

export type AdminAssigneeInlineControlsProps = {
	assignedEntity: Types.UnderwriterAssignment.UnderwriterAssignmentEntity;
	currentAssignee: UserId;
	label?: string;
};

export const AdminAssigneeInlineControls = ({
	assignedEntity,
	currentAssignee,
	label,
}: AdminAssigneeInlineControlsProps) => {
	return (
		<div className="flex items-center space-x-[8px] pt-[6px]">
			<span className="text-gray-700 text-[12px] font-[550]">{label ?? "Underwriter"}</span>
			<AdminAssigneeControls assignedEntity={assignedEntity} currentAssignee={currentAssignee} />
		</div>
	);
};

import { type ReactNode } from "react";
import { HiChevronLeft } from "react-icons/hi2";

export const AdminNotesTopicHeader = ({
	title,
	onBack,
	actionButton,
}: {
	title: string;
	onBack: () => void;
	actionButton?: ReactNode;
}) => {
	return (
		<div className="flex flex-row justify-between items-center w-full h-[40px] border-t border-b border-gray-100 bg-gray-50 p-5 sticky top-0 z-10">
			<div className="flex items-center gap-4">
				<div
					className="flex items-center justify-center h-[12px] w-[12px] cursor-pointer text-gray-500 hover:text-gray-800"
					onClick={() => onBack()}
				>
					<HiChevronLeft />
				</div>
				<div className="text-gray-700 text-[13px] font-medium text-ellipsis">{title}</div>
			</div>
			{actionButton ?? <></>}
		</div>
	);
};

import {
	type AttachmentFile,
	type CompanyContactId,
	FileExtension,
	type IndividualContactId,
	SuretyAccountStatus,
	SuretyType,
	formatToDollar,
	formatYesNo,
} from "@inrev/common";
import { DateTime } from "luxon";
import { type ReactNode, createContext, useEffect, useState } from "react";
import { FaRegSnowflake } from "react-icons/fa";
import {
	HiArrowPath,
	HiAtSymbol,
	HiOutlineCheckCircle,
	HiOutlineExclamationTriangle,
	HiOutlineMapPin,
	HiOutlinePencilSquare,
	HiOutlineXCircle,
	HiPlus,
	HiXMark,
} from "react-icons/hi2";
import { useIsFetching } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BarList } from "../../../components/charts/BarList";
import { AttachmentsSection } from "../../../components/layout/AttachmentsSection.tsx";
import { CommentsSection } from "../../../components/layout/CommentsSection";
import { ConfirmationModalClickWrapper } from "../../../components/layout/ConfirmationModalClickWrapper";
import { LoadingModal } from "../../../components/layout/LoadingModal";
import { cn } from "../../../components/lib/utils";
import { Button } from "../../../components/ui/Button";
import { Card } from "../../../components/ui/Card";
import { CardGrayBody } from "../../../components/ui/CardGrayBody";
import { DataTable } from "../../../components/ui/DataTable/DataTable";
import { LineItem } from "../../../components/ui/LineItem";
import { LocalFileUpload } from "../../../components/ui/LocalFileUpload";
import { NotificationCard } from "../../../components/ui/NotificationCard";
import { type PDFViewerControl, PDFViewerModal } from "../../../components/ui/PDFViewerModal";
import { SensitiveData } from "../../../components/ui/SensitiveData";
import { accountAttachmentTypeLabelMap } from "../../../constants";
import {
	useAdminFetchSuretyAccount,
	useAdminUploadCompletedIndemnityAgreement,
} from "../../../domain/admin/account/api";
import type { AdminSuretyAccount } from "../../../domain/admin/account/types";
import { getAccountPrimary } from "../../../domain/admin/account/utils";
import { useAdminDeleteFinancialStatement } from "../../../domain/admin/financial-statement/api.ts";
import { useComments } from "../../../domain/shared/comments/api";
import { useFollowers } from "../../../domain/shared/followers/api";
import type { LocallyUploadedFile } from "../../../types";
import { formatAddress, formatEmpty, formatName } from "../../../utils";
import { AdminWorkflowLayout } from "../layout/AdminWorkflowLayout.tsx";
import { AdminDataList } from "../shared/AdminDataList";
import { AdminFollowerControls } from "../shared/AdminFollowerControls";
import { NAICSCodesList } from "../shared/NAICSCodeList";
import { AdminTools } from "../shared/tools/AdminTools.tsx";
import { AdminAccountReUnderwriteModal } from "./re-underwrite/AdminAccountReUnderwriteModal.tsx";
import { FinancialStatementSection } from "./sections/FinancialStatementSection.tsx";
import { AdminCompanyModal } from "./shared/AccountCompanyModal.tsx";
import { AdminIndividualModal } from "./shared/AccountIndividualModal.tsx";
import { AdminAccountBondingLineSection } from "./shared/AdminAccountBondingLineSection";
import { AdminAccountBondsTable } from "./shared/AdminAccountBondsTable";
import { AdminAccountHeader } from "./shared/AdminAccountHeader";
import { AdminAccountNotificationStack } from "./shared/AdminAccountNotificationStack";
import { AdminAccountRequestsTable } from "./shared/AdminAccountRequestsTable";
import { AdminAccountReviewCard } from "./shared/AdminAccountReviewCard";

type _AdminSuretyAccountViewProps = {
	account: AdminSuretyAccount;
};

export const AdminReUnderwriteSuretyAccountModalContext = createContext<ReactNode>(undefined!);

export const _AdminSuretyAccountView = ({ account }: _AdminSuretyAccountViewProps) => {
	const { comments, commentsLoading, createComment, createCommentLoading } = useComments(
		"accounts",
		account.id,
		true,
	);
	const followersControl = useFollowers("accounts", account.id, true);
	const [attachments, setAttachments] = useState<AttachmentFile[]>(account.attachments);
	const primaryCompany = getAccountPrimary(account);
	const [indemnityAgreementFileUpload, setIndemnityAgreementFileUpload] = useState<
		LocallyUploadedFile[]
	>([]);
	const {
		uploadCompletedIndemnityAgreement,
		uploadCompletedIndemnityAgreementIsLoading,
		uploadCompletedIndemnityAgreementSuccess,
	} = useAdminUploadCompletedIndemnityAgreement(account.id, SuretyType.contract);
	const [pdfViewerControl, setPdfViewerControl] = useState<PDFViewerControl | undefined>(undefined);
	const isAccountFetching = useIsFetching(["suretyAccounts", account.id]);

	const [individualEditorOpen, setIndividualEditorOpen] = useState<boolean>(false);
	const [selectedIndividualId, setSelectedIndividualId] = useState<IndividualContactId | undefined>(
		undefined,
	);

	const [companyEditorOpen, setCompanyEditorOpen] = useState<boolean>(false);
	const [selectedCompanyId, setSelectedCompanyId] = useState<CompanyContactId | undefined>(
		undefined,
	);

	const { deleteFinancialStatementIsLoading } = useAdminDeleteFinancialStatement(account.id);
	const [reUnderwriteFormOpen, setReUnderwriteFormOpen] = useState(false);

	useEffect(() => {
		setAttachments(account.attachments);
	}, [account.attachments]);

	useEffect(() => {
		if (selectedIndividualId) setIndividualEditorOpen(true);
	}, [selectedIndividualId]);
	useEffect(() => {
		if (!individualEditorOpen) setSelectedIndividualId(undefined);
	}, [individualEditorOpen]);
	useEffect(() => {
		if (selectedCompanyId) setCompanyEditorOpen(true);
	}, [selectedCompanyId]);
	useEffect(() => {
		if (!companyEditorOpen) setSelectedCompanyId(undefined);
	}, [companyEditorOpen]);

	useEffect(() => {
		if (uploadCompletedIndemnityAgreementSuccess) setIndemnityAgreementFileUpload([]);
	}, [uploadCompletedIndemnityAgreementSuccess]);

	if (account.contract === undefined)
		throw new Error("Property 'contract' on a submitted surety account is undefined");
	return (
		<>
			<AdminReUnderwriteSuretyAccountModalContext.Provider
				value={
					<Button color="light-blue" filled onClick={() => setReUnderwriteFormOpen(true)}>
						<div className="flex items-center space-x-[8px]">
							<HiArrowPath className="text-[16px] text-white stroke-[.5]" />
							<span>Run Model</span>
						</div>
					</Button>
				}
			>
				<AdminWorkflowLayout
					title={account.displayName}
					subHeader={
						account.contract.underwriting.stale ? (
							<div className="flex w-full h-[36px] items-center justify-center space-x-[24px] bg-gray-100 shadow-md border-b border-b-gray-200">
								<span className="text-[13px] font-[500]">
									Updates were made to this account that may impact underwriting
								</span>
								<Button
									color="light-blue"
									filled
									onClick={() => setReUnderwriteFormOpen(true)}
									className="h-[24px] text-[12px]"
								>
									<div className="flex items-center space-x-[8px]">
										<HiArrowPath className="text-[12px] text-white stroke-[.5]" />
										<span>Run Model</span>
									</div>
								</Button>
							</div>
						) : undefined
					}
					toolbar={<AdminTools relation={{ relationType: "account", relationId: account.id }} />}
				>
					<div className="w-[900px] max-w-[900px] min-w-[785px]">
						<div className="flex flex-col space-y-[45px] pb-[125px]">
							<div className="flex flex-col space-y-[35px] mb-[-30px]">
								<AdminAccountNotificationStack account={account} className="pt-[25px]" />
								<AdminAccountHeader
									account={account}
									actionRequired={false}
									followersControl={followersControl}
								/>
								{account.status === SuretyAccountStatus.review && (
									<AdminAccountReviewCard account={account} />
								)}
								{account.status !== SuretyAccountStatus.review && (
									<AdminAccountBondingLineSection account={account} />
								)}
							</div>
							<AdminAccountRequestsTable account={account} />
							<AdminAccountBondsTable account={account} />
							<CardGrayBody
								header="Exposure"
								contentClassName="p-[40px] pt-[25px] space-y-[20px]"
								headerClassName="py-[15px] px-[25px] text-[17px] font-medium"
							>
								<div className="text-[16px]">Total: {formatToDollar(account.exposure.total)}</div>
								<div className="flex items-center space-x-[10px]">
									<Card className="p-[15px] shadow-sm">
										<BarList
											data={[
												{
													label: "Bond Requests",
													value: account.exposure.quotes,
												},
												{ label: "Bonds", value: account.exposure.bonds },
											]}
											valueFormatter={(value) => formatEmpty(formatToDollar(value, true))}
											className="text-[14px]"
										/>
									</Card>
									<Card className="p-[15px] shadow-sm">
										<BarList
											data={[
												{
													label: "Bid",
													value: account.exposure.contract.bid.total,
												},
												{
													label: "Final",
													value: account.exposure.contract.final.total,
												},
											]}
											valueFormatter={(value) => formatEmpty(formatToDollar(value, true))}
											className="text-[14px]"
										/>
									</Card>
								</div>
							</CardGrayBody>
							<CardGrayBody
								header="Character"
								contentClassName="p-[40px] pt-[25px] space-y-[35px]"
								headerClassName="py-[15px] px-[25px] text-[17px] font-medium"
							>
								<div className="flex flex-col space-y-[15px]">
									<div className="text-[15px] text-gray-500 italic">Company Credit Reports</div>
									<LineItem label="Adjusted FSR" className="px-[20px] pb-[5px]">
										{formatEmpty(account.adjustedFSR)}
									</LineItem>
									<DataTable
										name="admin_account_company_credit_reports"
										columnDefs={[
											{
												id: "timestampStatus",
												header: "Date Pulled",
												size: 140,
												minSize: 140,
												maxSize: 140,
												enableResizing: false,
												cell: (props) => {
													const defined = props.row.original.createdAt !== undefined;
													const createdAtDt = DateTime.fromISO(props.row.original.createdAt);
													const expired = createdAtDt.diffNow("years").years < -1;
													return (
														<div className="flex items-center space-x-[8px]">
															{!defined ? (
																<HiOutlineXCircle className="w-[18px] stroke-[1.75] text-inrev-gray-600" />
															) : expired ? (
																<HiOutlineExclamationTriangle className="text-[18px] stroke-[1.75] text-inrev-yellow" />
															) : props.row.original.success ? (
																<HiOutlineCheckCircle className="w-[18px] stroke-[1.75] text-inrev-green" />
															) : (
																<HiXMark className="w-[18px] stroke-[1.75] text-inrev-red" />
															)}
															<span>
																{defined
																	? DateTime.fromISO(props.row.original.createdAt).toFormat(
																			"MM/dd/yyyy",
																		)
																	: "--"}
															</span>
														</div>
													);
												},
											},
											{
												id: "companyName",
												accessorKey: "name",
												header: "Company",
												size: 200,
											},
											{
												id: "fsr",
												accessorKey: "fsr",
												header: "FSR",
												size: 75,
												minSize: 75,
											},
											{
												id: "lienBalance",
												accessorKey: "lienBalance",
												header: "Lien",
												size: 100,
												minSize: 100,
												accessorFn: ({ lienBalance }) => formatEmpty(formatToDollar(lienBalance)),
											},
											{
												id: "judgmentBalance",
												accessorKey: "judgmentBalance",
												header: "Judgment",
												size: 100,
												minSize: 100,
												accessorFn: ({ judgmentBalance }) =>
													formatEmpty(formatToDollar(judgmentBalance)),
											},
											{
												id: "bankruptcy",
												accessorKey: "bankruptcy",
												header: "Bankruptcy",
												size: 120,
												minSize: 120,
												accessorFn: ({ bankruptcy }) =>
													bankruptcy !== undefined
														? bankruptcy
															? "Yes"
															: "No"
														: formatEmpty(bankruptcy),
											},
										]}
										data={account.companies
											.filter((c) => c.config.contract.includeInUnderwriting)
											.map((company) => ({
												name: company.name,
												success: company.creditReports[0]?.success,
												fsr: company.creditReports[0]?.experianFsr,
												lienBalance: company.creditReports[0]?.lienBalance,
												judgmentBalance: company.creditReports[0]?.judgmentBalance,
												bankruptcy: company.creditReports[0]?.bankruptcy,
												createdAt: company.creditReports[0]?.createdAt,
											}))}
										tableBodyBorderColor="gray-200"
										tableClassName="shadow-sm"
										expandToRows
									/>
								</div>
								{account.individuals.length > 0 && (
									<div className="flex flex-col space-y-[15px]">
										<div className="text-[15px] text-gray-500 italic">
											Individual Credit Reports
										</div>
										<LineItem label="Average FICO" className="px-[20px] pb-[5px]">
											{formatEmpty(account.averageFICO)}
										</LineItem>
										<DataTable
											name="admin_account_individual_credit_reports"
											columnDefs={[
												{
													id: "timestampStatus",
													header: "Date Pulled",
													size: 140,
													minSize: 140,
													maxSize: 140,
													enableResizing: false,
													cell: (props) => {
														const defined = props.row.original.createdAt !== undefined;
														const createdAtDt = DateTime.fromISO(props.row.original.createdAt);
														const expired = createdAtDt.diffNow("years").years < -1;
														return (
															<div className="flex items-center space-x-[8px]">
																{!defined ? (
																	<HiOutlineXCircle className="w-[18px] stroke-[1.75] text-inrev-gray-600" />
																) : expired ? (
																	<HiOutlineExclamationTriangle className="text-[18px] stroke-[1.75] text-inrev-yellow" />
																) : props.row.original.success ? (
																	<HiOutlineCheckCircle className="w-[18px] stroke-[1.75] text-inrev-green" />
																) : props.row.original.failureReason === "frozen" ? (
																	<FaRegSnowflake className="w-[18px] stroke-[1.75] text-inrev-light-blue" />
																) : (
																	<HiXMark className="w-[18px] stroke-[1.75] text-inrev-red" />
																)}
																<span>
																	{defined
																		? DateTime.fromISO(props.row.original.createdAt).toFormat(
																				"MM/dd/yyyy",
																			)
																		: "--"}
																</span>
															</div>
														);
													},
												},
												{
													id: "name",
													accessorKey: "name",
													header: "Name",
													size: 200,
												},
												{
													id: "score",
													accessorKey: "score",
													header: "FICO",
													size: 75,
													minSize: 75,
													accessorFn: ({ score }) => formatEmpty(score),
												},
												{
													id: "bankruptcy",
													accessorKey: "bankruptcy",
													header: "Bankruptcy",
													size: 120,
													minSize: 120,
													accessorFn: ({ bankruptcy }) =>
														bankruptcy !== undefined
															? bankruptcy
																? "Yes"
																: "No"
															: formatEmpty(bankruptcy),
												},
											]}
											data={account.individuals
												.filter((i) => i.config.contract.includeInUnderwriting)
												.map((individual) => ({
													name: formatName(individual),
													success: individual.creditReports[0]?.success,
													score: individual.creditReports[0]?.score,
													bankruptcy: individual.creditReports[0]?.bankruptcy,
													createdAt: individual.creditReports[0]?.createdAt,
													failureReason: individual.creditReports[0]?.failureReason,
												}))}
											tableBodyBorderColor="gray-200"
											tableClassName="shadow-sm"
											expandToRows
										/>
									</div>
								)}
							</CardGrayBody>
							<CardGrayBody
								header="Capacity"
								headerClassName="py-[15px] px-[25px] text-[17px] font-medium"
								contentClassName="pt-[20px] pb-[35px] px-[45px] space-y-[12px] text-[14px]"
							>
								<div className="flex flex-col space-y-[10px] !pb-[15px]">
									<span>Specialty</span>
									<NAICSCodesList
										naicsCodes={account.contract.naicsCodes}
										showRatio
										className="!w-full min-w-full bg-white bg-opacity-60"
									/>
								</div>
								<LineItem
									label="Largest Project Completed"
									className="text-[14px]"
									labelClassName="text-[14px]"
								>
									{formatToDollar(account.contract.largestConstructionProjectCompleted)}
								</LineItem>
								<LineItem
									label="Years in Business or Credit Age (Main)"
									className="text-[14px]"
									labelClassName="text-[14px]"
								>
									{primaryCompany?.creditReports[0]?.yearsInBusinessOrCreditAge}
								</LineItem>
								<LineItem
									label="Failed to Complete a Job"
									className="text-[14px]"
									labelClassName="text-[14px]"
								>
									{formatYesNo(account.contract.failedToCompleteAConstructionJob)}
								</LineItem>
								<LineItem
									label="Caused a Loss To a Surety"
									className="text-[14px]"
									labelClassName="text-[14px]"
								>
									{formatYesNo(account.contract.causedSuretyLoss)}
								</LineItem>
								<LineItem
									label={`Current Backlog CTC${account.wipStatements.length ? ` (${DateTime.fromISO(account.wipStatements[0].createdAt).toFormat("MM/dd/yyyy")})` : ""}`}
									className="text-[14px]"
									labelClassName="text-[14px]"
								>
									{formatEmpty(formatToDollar(account.wipStatements[0]?.projectBacklogCost))}
								</LineItem>
								<LineItem
									label="Projects With a Gross Loss Last 36 Months"
									className="text-[14px]"
									labelClassName="text-[14px]"
								>
									{account.contract.constructionProjectsGrossLossGt10PercentLast36Months !==
									undefined
										? formatYesNo(
												account.contract.constructionProjectsGrossLossGt10PercentLast36Months,
											)
										: "--"}
								</LineItem>
								<LineItem
									label="Current Project With an Expected Gross Loss"
									className="text-[14px]"
									labelClassName="text-[14px]"
								>
									{account.contract.currentConstructionProjectExpectedGrossLoss !== undefined
										? formatYesNo(account.contract.currentConstructionProjectExpectedGrossLoss)
										: "--"}
								</LineItem>
							</CardGrayBody>
							<div className="w-full flex flex-col space-y-[30px]">
								<div className="text-[20px] !my-[-10px] font-medium">Individuals</div>
								<Button
									onClick={() => setIndividualEditorOpen(true)}
									className="ml-[-5px] w-fit"
									color="gray"
									rounded
									filled
									thinFont
								>
									<div className="flex min-w-fit items-center space-x-[5px]">
										<HiPlus className="text-[20px] text-gray-800" />
										<div className="min-w-fit">Add Individual</div>
									</div>
								</Button>
								{individualEditorOpen && (
									<AdminIndividualModal
										account={account}
										selectedIndividualId={selectedIndividualId}
										onClose={() => setIndividualEditorOpen(false)}
									/>
								)}
								<div className="w-full flex gap-[15px] flex-wrap">
									{!account.individuals.length && "None"}
									{!!account.individuals.length &&
										account.individuals.map((individual, index) => (
											<Card
												key={index}
												className="px-[22px] py-[15px] w-fit space-y-[7px] shadow-sm bg-white"
											>
												<div className="justify-between flex items-center space-x-[10px]">
													<span className="text-[15px] font-medium">{formatName(individual)}</span>
													<Button
														color="gray"
														className="border-0 p-[8px] w-fit h-fit my-[-3px]"
														onClick={() => setSelectedIndividualId(individual.id)}
													>
														<HiOutlinePencilSquare className="text-[20px] text-gray-600" />
													</Button>
												</div>
												<div className="flex flex-col">
													<div className="flex items-center space-x-[8px]">
														<HiOutlineMapPin className="text-[16px] stroke-[1.75] text-gray-500" />
														<span className="text-[14px] text-gray-700">
															{formatEmpty(formatAddress(individual.address))}
														</span>
													</div>
													<div className="flex items-center space-x-[8px]">
														<HiAtSymbol className="text-[16px] stroke-[0.5] text-gray-500" />
														<span className="text-[14px] text-gray-700">
															{formatEmpty(individual.email)}
														</span>
													</div>
													{individual.ssn && individual.config.contract.includeInUnderwriting && (
														<>
															<div className="flex items-center space-x-[8px] leading-[18px]">
																<span className="text-[13px] font-medium text-gray-500 italic">
																	SSN
																</span>
																<SensitiveData
																	value={individual.ssn}
																	className="text-[14px] text-gray-700"
																/>
															</div>
															<div className="flex items-center space-x-[8px] leading-[18px]">
																<span className="text-[13px] font-medium text-gray-500 italic">
																	OFAC
																</span>
																<span
																	className={cn(
																		"text-[14px]",
																		individual.ofacCheck?.hit ? "text-inrev-red" : "text-gray-700",
																	)}
																>
																	{formatYesNo(individual.ofacCheck?.hit)}
																</span>
																{individual.ofacCheck && (
																	<span className="text-[13px] text-gray-500 italic">
																		(
																		{DateTime.fromISO(
																			individual.ofacCheck.createdAt,
																		).toLocaleString(DateTime.DATE_SHORT)}
																		)
																	</span>
																)}
																{individual.ofacCheck &&
																	DateTime.fromISO(individual.ofacCheck.createdAt).diffNow("years")
																		.years < -1 && (
																		<HiOutlineExclamationTriangle className="text-[18px] stroke-[1.75] text-inrev-yellow" />
																	)}
															</div>
														</>
													)}
													<div className="flex items-center space-x-[8px] pt-[2px]">
														<span className="text-[13px] font-medium text-gray-500 italic">
															Included in underwriting?
														</span>
														<span className="text-[14px] text-gray-700">
															{formatEmpty(
																formatYesNo(individual.config.contract.includeInUnderwriting),
															)}
														</span>
													</div>
													<div className="flex items-center space-x-[8px]">
														<span className="text-[13px] font-medium text-gray-500 italic">
															Included in indemnity?
														</span>
														<span className="text-[14px] text-gray-700">
															{formatEmpty(
																formatYesNo(individual.config.contract.includeInIndemnity),
															)}
														</span>
													</div>
													{individual.spouse && (
														<div className="flex flex-col space-y-[5px] leading-[18px] my-[10px]">
															<span className="text-[13px] font-medium text-gray-500 italic">
																SPOUSE
															</span>
															<div className="flex flex-col px-[10px] text-[14px] text-gray-700">
																<span>{formatName(individual.spouse)}</span>
																<span>{formatEmpty(formatAddress(individual.spouse.address))}</span>
																<span>{formatEmpty(individual.spouse.email)}</span>
															</div>
														</div>
													)}
												</div>
											</Card>
										))}
								</div>
							</div>
							<div className="w-full flex flex-col space-y-[30px]">
								<div className="text-[20px] !my-[-10px] font-medium">Companies</div>
								<Button
									onClick={() => setCompanyEditorOpen(true)}
									className="ml-[-5px] w-fit"
									color="gray"
									rounded
									filled
									thinFont
								>
									<div className="flex min-w-fit items-center space-x-[5px]">
										<HiPlus className="text-[20px] text-gray-800" />
										<div className="min-w-fit">Add Company</div>
									</div>
								</Button>
								{companyEditorOpen && (
									<AdminCompanyModal
										account={account}
										selectedCompanyId={selectedCompanyId}
										onClose={() => setCompanyEditorOpen(false)}
									/>
								)}
								<div className="w-full flex gap-[20px] flex-wrap">
									{!account.companies.length && "None"}
									{!!account.companies.length &&
										account.companies.map((company, index) => (
											<Card
												key={index}
												className="px-[22px] py-[15px] w-fit space-y-[7px] shadow-sm bg-white shrink-0"
											>
												<div className="justify-between flex items-center space-x-[10px]">
													<span className="text-[15px] font-medium">{company.name}</span>
													<Button
														color="gray"
														className="border-0 p-[8px] w-fit h-fit my-[-3px]"
														onClick={() => setSelectedCompanyId(company.id)}
													>
														<HiOutlinePencilSquare className="text-[20px] text-gray-600" />
													</Button>
												</div>
												<div className="flex flex-col">
													<div className="flex items-center space-x-[8px]">
														<HiOutlineMapPin className="text-[16px] stroke-[1.75] text-gray-500" />
														<span className="text-[14px] text-gray-700">
															{formatEmpty(formatAddress(company.address))}
														</span>
													</div>
													<div className="flex items-center space-x-[8px]">
														<span className="text-[13px] font-medium text-gray-500 italic">
															FEIN
														</span>
														<span className="text-[14px] text-gray-700">
															{formatEmpty(company.fein)}
														</span>
													</div>
													<div className="flex items-center space-x-[8px]">
														<span className="text-[13px] font-medium text-gray-500 italic">
															Included in underwriting?
														</span>
														<span className="text-[14px] text-gray-700">
															{formatEmpty(
																formatYesNo(company.config.contract.includeInUnderwriting),
															)}
														</span>
													</div>
													<div className="flex items-center space-x-[8px]">
														<span className="text-[13px] font-medium text-gray-500 italic">
															Included in indemnity?
														</span>
														<span className="text-[14px] text-gray-700">
															{formatEmpty(formatYesNo(company.config.contract.includeInIndemnity))}
														</span>
													</div>
												</div>
											</Card>
										))}
								</div>
							</div>
							<FinancialStatementSection account={account} />
							<CardGrayBody
								header={
									<div className="flex items-center space-x-[10px]">
										<div className="flex-1">Indemnity</div>
										<div className="flex items-center space-x-[5px] text-[14px] text-gray-500 italic">
											{account.contract.indemnity.upToDate && (
												<>
													<HiOutlineCheckCircle className="text-[18px] stroke-[1.75] text-inrev-green" />
													<span>Up to date</span>
												</>
											)}
											{!account.contract.indemnity.upToDate && (
												<>
													<HiXMark className="text-[18px] stroke-[1.75] text-inrev-red" />
													<span>Not up to date</span>
												</>
											)}
										</div>
									</div>
								}
								contentClassName="p-[40px] pt-[25px] space-y-[35px]"
								headerClassName="py-[15px] px-[25px] text-[17px] font-medium"
							>
								<div className="flex flex-col space-y-[14px] w-fit min-w-[70%] auto-rows-min">
									<div className="text-[15px] text-gray-500 italic">Required Indemnitors</div>
									<div className="flex flex-col space-y-[5px]">
										{account.contract.indemnity.requiredIndemnitors.companies.map(
											(indemnitor, index) => (
												<Card
													key={index}
													className="flex items-center space-x-[30px] px-[15px] py-[10px] shadow-sm"
												>
													<span className="text-[14px] flex-1">{indemnitor.name}</span>
												</Card>
											),
										)}
										{account.contract.indemnity.requiredIndemnitors.individuals.map(
											(indemnitor, index) => (
												<Card
													key={index}
													className="flex items-center space-x-[30px] px-[15px] py-[10px] shadow-sm"
												>
													<div className="flex items-center flex-1 space-x-[10px] text-[14px]">
														<span>{indemnitor.name}</span>
														{indemnitor.email && (
															<span className="text-gray-500 font-medium">{indemnitor.email}</span>
														)}
													</div>
												</Card>
											),
										)}
									</div>
								</div>
								{!!(
									account.contract.indemnity.indemnityWaivers.companies.length ||
									account.contract.indemnity.indemnityWaivers.individuals.length
								) && (
									<div className="flex flex-col space-y-[14px] w-fit min-w-[70%] auto-rows-min">
										<div className="text-[15px] text-gray-500 italic">Waived Indemnitors</div>
										<div className="flex flex-col space-y-[5px]">
											{account.contract.indemnity.indemnityWaivers.companies.map(
												(waiver, index) => (
													<Card
														key={index}
														className="flex items-center space-x-[30px] px-[15px] py-[10px] shadow-sm"
													>
														<span className="text-[14px] flex-1">{waiver.name}</span>
													</Card>
												),
											)}
											{account.contract.indemnity.indemnityWaivers.individuals.map(
												(indemnitor, index) => (
													<Card
														key={index}
														className="flex items-center space-x-[30px] px-[15px] py-[10px] shadow-sm"
													>
														<div className="flex items-center flex-1 space-x-[10px] text-[14px]">
															<span>{indemnitor.name}</span>
															{indemnitor.email && (
																<span className="text-gray-500 font-medium">
																	{indemnitor.email}
																</span>
															)}
														</div>
													</Card>
												),
											)}
										</div>
									</div>
								)}
								<div className="flex flex-col space-y-[10px] w-full auto-rows-min">
									<div className="flex items-center justify-between space-x-[15px]">
										<div className="text-[15px] text-gray-500 italic">Indemnity Agreement</div>
										{!!account.contract.indemnity.indemnityAgreements.length && (
											<Button
												color="gray"
												filled
												thinFont
												onClick={() =>
													setPdfViewerControl({
														fetchUrl: `/v2/admin/surety/accounts/${account.id}/indemnity-agreement/${SuretyType.contract}`,
														queryKey: [
															"indemnityAgreementFileUrl",
															account.id,
															SuretyType.contract,
														],
													})
												}
											>
												View
											</Button>
										)}
									</div>
									{!!account.contract.indemnity.indemnityAgreements.length &&
										account.contract.indemnity.indemnityAgreements[0].status === "pending" &&
										account.contract.indemnity.indemnityAgreements[0].signatureMethod ===
											"physical" && (
											<NotificationCard
												type="action"
												text="Upload copy of completed physical indemnity agreement"
											/>
										)}
									{!account.contract.indemnity.indemnityAgreements.length && (
										<span className="!mt-[10px] text-[15px] text-gray-800">None</span>
									)}
									{!!account.contract.indemnity.indemnityAgreements.length && (
										<Card className="flex flex-col space-y-[20px] px-[15px] py-[10px] pb-[20px] shadow-sm">
											<AdminDataList
												data={[
													{
														label: "Status",
														value: account.contract.indemnity.indemnityAgreements[0].status,
													},
													{
														label: "Signature Method",
														value:
															account.contract.indemnity.indemnityAgreements[0].signatureMethod,
													},
													{
														label: "Indemnitors",
														value: (
															<div className="w-fit flex flex-col gap-[8px] text-[14px]">
																{account.contract.indemnity.indemnityAgreements[0].indemnitors.companies.map(
																	(indemnitor, index) => (
																		<div
																			key={index}
																			className="flex justify-between items-center flex-1 space-x-[10px] text-[14px]"
																		>
																			<span className="text-left">{indemnitor.name}</span>
																			{indemnitor.signerEmail && (
																				<>
																					<div className="w-[1px] h-[18px] bg-gray-200"></div>
																					<span className="text-gray-500 font-medium text-right">
																						{indemnitor.signerEmail}
																					</span>
																				</>
																			)}
																			{account.contract.indemnity.indemnityAgreements[0]
																				.signatureMethod === "electronic" && (
																				<>
																					<div className="w-[1px] h-[18px] bg-gray-200"></div>
																					{indemnitor.completed !== undefined &&
																						!indemnitor.completed && (
																							<span className="italic font-normal text-gray-500">
																								Hasn't signed
																							</span>
																						)}
																					{indemnitor.completed && (
																						<span className="font-medium text-inrev-green">
																							Signed
																						</span>
																					)}
																				</>
																			)}
																		</div>
																	),
																)}
																{account.contract.indemnity.indemnityAgreements[0].indemnitors.individuals.map(
																	(indemnitor, index) => (
																		<div
																			key={index}
																			className="flex justify-between items-center flex-1 space-x-[10px] text-[14px]"
																		>
																			<span className="text-left">{indemnitor.name}</span>
																			{indemnitor.signerEmail && (
																				<>
																					<div className="w-[1px] h-[18px] bg-gray-200"></div>
																					<span className="text-gray-500 font-medium text-right">
																						{indemnitor.signerEmail}
																					</span>
																				</>
																			)}
																			{account.contract.indemnity.indemnityAgreements[0]
																				.signatureMethod === "electronic" && (
																				<>
																					<div className="w-[1px] h-[18px] bg-gray-200"></div>
																					{!indemnitor.completed && (
																						<span className="italic font-normal text-gray-500">
																							Hasn't signed
																						</span>
																					)}
																					{indemnitor.completed && (
																						<span className="font-medium text-inrev-green">
																							Signed
																						</span>
																					)}
																				</>
																			)}
																		</div>
																	),
																)}
															</div>
														),
													},
												]}
											/>
											{account.contract.indemnity.indemnityAgreements.length && (
												<div className="flex flex-col items-end space-y-[20px]">
													<LocalFileUpload
														value={indemnityAgreementFileUpload}
														allowedTypesAndLabels={{
															indemnity_agreement: "Completed Indemnity Agreement",
														}}
														maxFiles={1}
														allowedExtensions={[FileExtension.pdf]}
														onChange={setIndemnityAgreementFileUpload}
													/>
													<ConfirmationModalClickWrapper
														message="Are you sure?"
														subMessage="The system will issue bonds for any request in this account that is only pending an indemnity agreement"
														shouldConfirm={!!indemnityAgreementFileUpload.length}
														onConfirm={() =>
															uploadCompletedIndemnityAgreement(indemnityAgreementFileUpload[0])
														}
													>
														<Button
															color="light-blue"
															thinFont
															filled
															className="w-fit"
															disabled={!indemnityAgreementFileUpload.length}
														>
															Upload
														</Button>
													</ConfirmationModalClickWrapper>
													{uploadCompletedIndemnityAgreementIsLoading && <LoadingModal />}
													{deleteFinancialStatementIsLoading && <LoadingModal />}
												</div>
											)}
										</Card>
									)}
								</div>
							</CardGrayBody>
							<CommentsSection
								comments={comments}
								commentsLoading={commentsLoading}
								createComment={createComment}
								createCommentLoading={createCommentLoading}
								commentDownloadHeader={`Account For ${account.displayName}`}
								headerEnd={
									<AdminFollowerControls
										{...followersControl}
										manageFollowersSubtext="Followers will receive email notifications for all comments and changes to the status of this account"
										agencyId={account.agency.id}
									/>
								}
							/>
							<AttachmentsSection
								attachments={attachments}
								allowTypeConfirmation={true}
								downloadAllName={`${account.displayName} Attachments`}
								upload={{
									url: `/v2/admin/surety/accounts/${account.id}/attachments`,
									onChange: (val) => setAttachments(val),
									allowedTypesAndLabels: accountAttachmentTypeLabelMap,
									invalidateQueryKeys: [["adminSuretyAccounts", account.id]],
								}}
								download={{
									baseUrl: `/v2/admin/surety/accounts/${account.id}/attachments`,
									baseQueryKey: "adminAccountAttachments",
								}}
								typeLabelMap={accountAttachmentTypeLabelMap}
							/>
						</div>
					</div>
					<PDFViewerModal
						control={pdfViewerControl}
						onClose={() => setPdfViewerControl(undefined)}
						header="View File"
					/>
					{isAccountFetching > 0 && <LoadingModal />}
				</AdminWorkflowLayout>
			</AdminReUnderwriteSuretyAccountModalContext.Provider>
			{reUnderwriteFormOpen && (
				<AdminAccountReUnderwriteModal
					account={account}
					onClose={() => setReUnderwriteFormOpen(false)}
				/>
			)}
		</>
	);
};

export const AdminSuretyAccountView = () => {
	const { id } = useParams();
	const { suretyAccount, suretyAccountIsLoading, suretyAccountError } = useAdminFetchSuretyAccount(
		id!,
	);
	const navigate = useNavigate();

	useEffect(() => {
		if (suretyAccountError) {
			navigate("/accounts");
		}
	}, [suretyAccountError]);

	if (suretyAccountIsLoading || suretyAccount === undefined) return <LoadingModal />;
	return <_AdminSuretyAccountView account={suretyAccount} />;
};

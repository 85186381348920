import { z } from 'zod';
import { workInProgressStatementId, companyContactId } from '../../../types/opaque.mjs';
import { isoDateSchema, dateTimeToISODateTimeSchema } from '../../../types/utils.mjs';
import '../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../types/credit-report/company-credit-report.mjs';
import '../../../types/credit-report/individual-credit-report.mjs';
import '../../../types/payment/payment-template.mjs';
import '../../../types/surety/account/surety-account.types.mjs';
import '../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../types/surety/bond/surety-bond.types.mjs';
import '../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../types/user/user.types.mjs';
import '../../../types/entityRelation/entityRelation.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = z.object({
            id: workInProgressStatementId,
            companyContactId: companyContactId,
            projectBacklogCost: z.number(),
            projectBacklogGrossProfit: z.number().optional(),
            date: isoDateSchema,
            createdAt: dateTimeToISODateTimeSchema,
            updatedAt: dateTimeToISODateTimeSchema
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };

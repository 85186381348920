import { ReactNode } from "react";
import { cn } from "../../lib/utils";

type RowActionButtonsProps = {
	children: ReactNode;
	alwaysShow?: boolean;
	className?: string;
};

export const RowActionButtons = ({ children, alwaysShow, className }: RowActionButtonsProps) => (
	<div
		className={cn(
			"flex items-center space-x-[5px] w-fit min-w-fit h-full sticky right-0 justify-end flex-1 bg-white",
			className ?? "",
			alwaysShow
				? undefined
				: "opacity-0 group-hover:opacity-100 transition-opacity duration-[450ms] ease-in-out",
		)}
		onClick={(e) => e.stopPropagation()}
	>
		<div className="w-fit h-full bg-white group-hover:bg-[rgba(251,252,253,1)]">{children}</div>
	</div>
);

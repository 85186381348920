import { Dtos, NoteId, NoteTopicId, Relations } from "@inrev/common";
import { useContext, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuthenticatedUser } from "../../../providers/AuthenticatedUserProvider";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { useRequest } from "../../../utils/request";
import { ApiError } from "../../shared/types";
import { type AdminNote, AdminNoteTopic } from "./types";

export const adminNotesQueryKeys = {
	all: (relation: Relations.Notes) => {
		return ["adminNoteTopics", relation.relationType];
	},
	topicsByRelation: (relation: Relations.Notes) => {
		return [...adminNotesQueryKeys.all(relation), `${relation.relationId}`];
	},
};

export const useFetchAdminNoteTopics = (relation: Relations.Notes) => {
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const { get } = useRequest();

	const _topics = useQuery({
		queryKey: adminNotesQueryKeys.topicsByRelation(relation),
		queryFn: async () => {
			try {
				return await get<Dtos.Admin.AdminNoteTopic.Get.Response[]>(
					`/v2/admin/admin-notes/note-topics?relationType=${relation.relationType}&relationId=${relation.relationId}`,
				);
			} catch (error) {
				console.error(error);
				triggerErrorModal(error as ApiError);
			}
		},
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		staleTime: 1000 * 60 * 5,
	});

	const sortNotes = (notes: AdminNote[]) => {
		return notes.sort((a, b) => {
			if (a.pinned && !b.pinned) return -1;
			if (!a.pinned && b.pinned) return 1;
			if (a.pinned && b.pinned) {
				if (a.createdAt > b.createdAt) return -1;
				if (a.createdAt < b.createdAt) return 1;
				return 0;
			}
			if (a.createdAt > b.createdAt) return -1;
			if (a.createdAt < b.createdAt) return 1;
			return 0;
		});
	};

	return useMemo(() => {
		const topicsWithoutDefault = (() => {
			const topics = _topics.data
				?.filter((topic) => !topic.default)
				.sort((a, b) => a.name.localeCompare(b.name));
			if (topics !== undefined)
				return topics
					.map((topic) => ({
						...topic,
						notes: sortNotes(topic.notes.filter((note) => !note.archived)),
					}))
					.filter((topic) => !topic.archived);
		})();
		const defaultTopic = (() => {
			const topic = _topics.data?.find((topic) => topic.default);
			if (topic !== undefined)
				return {
					...topic,
					notes: sortNotes([
						...topic.notes.filter((note) => !note.archived),
						...(() => {
							if (topicsWithoutDefault !== undefined) {
								return topicsWithoutDefault.flatMap((topic) =>
									topic.notes.filter((note) => note.pinned),
								);
							}
							return [];
						})(),
					]),
				};
		})();
		const defaultArchivedTopic = (() => {
			const topic = _topics.data?.find((topic) => topic.default);
			if (topic !== undefined)
				return {
					...topic,
					notes: sortNotes(topic.notes.filter((note) => note.archived)),
				};
		})();
		const archivedTopics = (() => {
			const topics = _topics.data
				?.filter((topic) => !topic.default)
				.sort((a, b) => a.name.localeCompare(b.name));
			if (topics !== undefined)
				return topics.reduce<AdminNoteTopic[]>((acc, topic) => {
					if (topic.archived) {
						acc.push({ ...topic, notes: sortNotes(topic.notes) });
					}
					if (topic.notes.some((note) => note.archived && !topic.archived)) {
						acc.push({
							...topic,
							notes: sortNotes(topic.notes.filter((note) => note.archived)),
						});
					}
					return acc;
				}, []);
		})();

		return {
			defaultTopic,
			topics: topicsWithoutDefault,
			defaultArchivedTopic,
			archivedTopics,
			adminNoteTopicsIsLoading: _topics.isLoading,
			adminNoteTopicsError: _topics.error,
		};
	}, [_topics]);
};

export const useCreateAdminNoteTopic = () => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (data: Dtos.Admin.AdminNoteTopic.Create.Request) =>
			post<Dtos.Admin.AdminNoteTopic.Create.Response>("/v2/admin/admin-notes/note-topics", data),
		onMutate: async ({ name, relationType, relationId }) => {
			const relation = { relationType, relationId } as Relations.Notes;
			await queryClient.cancelQueries(adminNotesQueryKeys.topicsByRelation(relation));
			const previousTopics = queryClient.getQueryData<AdminNoteTopic[]>(
				adminNotesQueryKeys.topicsByRelation(relation),
			);
			const tempTopicId = Math.random().toString(36).substring(7) as NoteTopicId;
			queryClient.setQueryData<AdminNoteTopic[]>(
				adminNotesQueryKeys.topicsByRelation(relation),
				(oldTopics: AdminNoteTopic[] | undefined) => {
					if (!oldTopics) return [];
					return [
						...oldTopics,
						{
							id: tempTopicId,
							name,
							summary: "",
							...relation,
							default: false,
							archived: false,
							notes: [],
							createdAt: new Date().toISOString(),
							updatedAt: new Date().toISOString(),
						},
					];
				},
			);
			return { previousTopics, tempTopicId, relation };
		},
		onSuccess: (newTopic, inputData, context) => {
			const { relationId, relationType } = inputData;
			const relation = { relationId, relationType } as Relations.Notes;
			queryClient.setQueryData(
				adminNotesQueryKeys.topicsByRelation(relation),
				(oldTopics: AdminNoteTopic[] | undefined) => {
					if (!oldTopics) return [newTopic];
					return oldTopics.map((topic) => {
						if (topic.id === context?.tempTopicId) {
							return newTopic;
						}
						return topic;
					});
				},
			);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		createAdminNoteTopic: mutation.mutate,
		createAdminNoteTopicIsLoading: mutation.isLoading,
	};
};

export const useUpdateAdminNoteTopic = () => {
	const queryClient = useQueryClient();
	const { patch } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async ({
			data,
			noteTopicId,
		}: {
			data: Dtos.Admin.AdminNoteTopic.Update.Request;
			noteTopicId: NoteTopicId;
			relation: Relations.Notes;
		}) => {
			return await patch<Dtos.Admin.AdminNoteTopic.Update.Response>(
				`/v2/admin/admin-notes/note-topics/${noteTopicId}`,
				data,
			);
		},
		onMutate: async ({
			data,
			noteTopicId,
			relation,
		}: {
			data: Dtos.Admin.AdminNoteTopic.Update.Request;
			noteTopicId: NoteTopicId;
			relation: Relations.Notes;
		}) => {
			queryClient.cancelQueries({
				queryKey: adminNotesQueryKeys.topicsByRelation(relation),
			});
			const previousTopics = queryClient.getQueryData<AdminNoteTopic[]>(
				adminNotesQueryKeys.topicsByRelation(relation),
			);
			queryClient.setQueryData<AdminNoteTopic[]>(
				adminNotesQueryKeys.topicsByRelation(relation),
				(old) => {
					if (!old) return [];
					return old.map((topic) => (topic.id === noteTopicId ? { ...topic, ...data } : topic));
				},
			);
			const result = {
				previousTopics,
				relation,
				noteTopicId,
				data,
			};
			return result;
		},
		onSuccess(result, { relation }) {
			if (result) {
				queryClient.setQueryData<AdminNoteTopic[]>(
					adminNotesQueryKeys.topicsByRelation(relation),
					(old) => {
						if (!old) return [];
						return old.map((topic) => (topic.id === result.id ? { ...topic, ...result } : topic));
					},
				);
			}
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		updateAdminNoteTopic: mutation.mutate,
		updateAdminNoteTopicIsLoading: mutation.isLoading,
	};
};

export const useDeleteAdminNoteTopic = () => {
	const queryClient = useQueryClient();
	const { _delete } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async ({ noteTopicId }: { noteTopicId: NoteTopicId; relation: Relations.Notes }) =>
			_delete(`/v2/admin/admin-notes/note-topics/${noteTopicId}`, undefined, "none"),
		onMutate: async ({ noteTopicId, relation }) => {
			queryClient.setQueryData<AdminNoteTopic[]>(
				adminNotesQueryKeys.topicsByRelation(relation),
				(old) => {
					if (!old) return [];
					return old.reduce<AdminNoteTopic[]>((acc, topic) => {
						if (topic.id === noteTopicId) {
							if (topic.summary === "" && topic.notes.length === 0) {
								return acc;
							}
							acc.push({ ...topic, archived: true });
						} else {
							acc.push(topic);
						}
						return acc;
					}, []);
				},
			);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		deleteAdminNoteTopic: mutation.mutate,
		deleteAdminNoteTopicIsLoading: mutation.isLoading,
	};
};

export const useCreateAdminNote = () => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const { user } = useAuthenticatedUser();

	const mutation = useMutation({
		mutationFn: async ({
			content,
			topicId,
		}: { content: string; topicId: NoteTopicId; relation: Relations.Notes }) =>
			post<Dtos.Admin.AdminNote.Create.Response>(`/v2/admin/admin-notes/note-topics/${topicId}`, {
				content,
			}),
		onMutate: async ({ content, relation, topicId }) => {
			await queryClient.cancelQueries(adminNotesQueryKeys.topicsByRelation(relation));

			const previousNotes = queryClient.getQueryData<AdminNoteTopic[]>(
				adminNotesQueryKeys.topicsByRelation(relation),
			);

			const tempId = Math.random().toString(36).substring(7) as NoteId;

			queryClient.setQueryData(
				adminNotesQueryKeys.topicsByRelation(relation),
				(old: AdminNoteTopic[] | undefined) => {
					const newNote = {
						id: tempId,
						content,
						topicId,
						fromUserFirstName: user.firstName,
						fromUserLastName: user.lastName,
						pinned: false,
						archived: false,
						createdAt: new Date().toISOString(),
						updatedAt: new Date().toISOString(),
					};
					if (!old) throw new Error("No topics found");
					return old.map((topic) => {
						if (topic.id === topicId) {
							return {
								...topic,
								notes: [newNote, ...topic.notes],
							};
						}
						return topic;
					});
				},
			);
			return { previousNotes, tempId };
		},
		onSuccess: (data, { relation, topicId }, context) => {
			if (!context) throw new Error("No context found");
			queryClient.setQueryData(
				adminNotesQueryKeys.topicsByRelation(relation),
				(old: AdminNoteTopic[] | undefined) => {
					if (!old) throw new Error("No topics found");
					return old.map((topic) => {
						if (topic.id === topicId) {
							return {
								...topic,
								notes: topic.notes.map((note) => {
									if (note.id === context.tempId) {
										return {
											...data,
										};
									}
									return note;
								}),
							};
						}
						return topic;
					});
				},
			);
		},
		onError: (error: ApiError, inputData, context) => {
			console.error(error);
			queryClient.setQueriesData(
				adminNotesQueryKeys.topicsByRelation(inputData.relation),
				context?.previousNotes,
			);
			triggerErrorModal(error);
		},
	});

	return {
		createAdminNote: mutation.mutate,
		createAdminNoteIsLoading: mutation.isLoading,
	};
};

export const useUpdateAdminNote = () => {
	const queryClient = useQueryClient();
	const { patch } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async ({
			data,
			noteId,
			topicId,
		}: {
			data: Dtos.Admin.AdminNote.Update.Request;
			noteId: string;
			topicId: NoteTopicId;
			relation: Relations.Notes;
		}) => {
			return await patch<Dtos.Admin.AdminNote.Update.Response>(
				`/v2/admin/admin-notes/note-topics/${topicId}/notes/${noteId}`,
				data,
			);
		},
		onMutate: ({ data, noteId, topicId, relation }) => {
			queryClient.cancelQueries({
				queryKey: adminNotesQueryKeys.topicsByRelation(relation),
			});
			queryClient.setQueryData(
				adminNotesQueryKeys.topicsByRelation(relation),
				(oldTopics: AdminNoteTopic[] | undefined) => {
					if (!oldTopics) return [];
					return oldTopics.map((topic) => {
						if (topic.id === topicId) {
							return {
								...topic,
								notes: topic.notes.map((note) => {
									if (note.id === noteId) {
										return {
											...note,
											...data,
										};
									}
									return note;
								}),
							};
						}
						return topic;
					});
				},
			);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});
	return {
		updateAdminNote: mutation.mutate,
		updateAdminNoteIsLoading: mutation.isLoading,
	};
};

import { formatToDollar } from "@inrev/common";
import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { getStatusTagByStatus } from "../../../components/ui/StatusTag";
import { RowActionButtons } from "../../../components/ui/row-action-buttons/RowActionButtons";
import { bondRequestTypeLabelMap } from "../../../constants";
import { BondRequestPreview } from "../../../domain/agent/request/types";
import { formatEmpty } from "../../../utils";
import { AdminAssigneeControls } from "../shared/AdminAssigneeControls";

export const requestsTableColumns: ColumnDef<BondRequestPreview>[] = [
	{
		id: "underwriter",
		header: undefined,
		accessorKey: "underwriterUserId",
		cell: (props) => (
			<RowActionButtons alwaysShow={true}>
				<AdminAssigneeControls
					assignedEntity={{ entityType: "quote", entityId: props.row.original.id }}
					currentAssignee={props.row.original.underwriterUserId!}
				/>
			</RowActionButtons>
		),
		meta: {
			noTruncate: true,
		},
		size: 60,
		minSize: 60,
		maxSize: 60,
		enableResizing: true,
		enableSorting: true,
		enableGlobalFilter: false,
		enableHiding: false,
		enableColumnFilter: true,
		enablePinning: false,
		enableGrouping: false,
		enableMultiSort: false,
	},
	{
		id: "status",
		accessorKey: "status",
		header: "Status",
		filterFn: "equalsString",
		cell: (cell) => getStatusTagByStatus(cell.row.original.status),
		meta: {
			noTruncate: true,
		},
		size: 175,
		minSize: 175,
	},
	{
		id: "principal_name",
		accessorKey: "principalName",
		accessorFn: ({ principalName }) => formatEmpty(principalName),
		header: "Principal",
		size: 180,
		minSize: 130,
	},
	{
		id: "amount",
		accessorKey: "amount",
		header: "Amount",
		cell: (context) => formatEmpty(formatToDollar(context.getValue<string>())),
		size: 180,
		minSize: 130,
	},
	{
		id: "description",
		accessorKey: "description",
		accessorFn: ({ description }) => formatEmpty(description),
		header: "Description",
		size: 180,
		minSize: 130,
	},
	{
		id: "type",
		accessorKey: "suretySubType",
		accessorFn: ({ suretySubType }) =>
			formatEmpty(!!suretySubType ? bondRequestTypeLabelMap[suretySubType] : undefined),
		header: "Type",
		size: 180,
		minSize: 130,
	},
	{
		id: "account_name",
		accessorKey: "accountName",
		accessorFn: ({ accountName }) => formatEmpty(accountName),
		header: "Account",
		size: 180,
		minSize: 130,
	},
	{
		id: "submittedAt",
		accessorKey: "submittedAt",
		accessorFn: ({ submittedAt }) =>
			submittedAt !== undefined ? DateTime.fromISO(submittedAt).toJSDate() : undefined,
		cell: (props) =>
			props.row.original.submittedAt !== undefined
				? DateTime.fromISO(props.row.original.submittedAt).toLocaleString(DateTime.DATE_MED)
				: "--",
		header: "Submitted",
		size: 150,
		minSize: 130,
		enableGlobalFilter: false,
		sortingFn: "datetime",
	},
];

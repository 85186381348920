import { ConfirmationModalClickWrapper } from "../../../components/layout/ConfirmationModalClickWrapper";
import { ArchiveRowActionButton } from "../../../components/ui/row-action-buttons/ArchiveRowActionButton";
import { DeleteRowActionButton } from "../../../components/ui/row-action-buttons/DeleteRowActionButton";
import { UnrchiveRowActionButton } from "../../../components/ui/row-action-buttons/UnarchiveRowActionButton";
import {
	useArchiveRequest,
	useDeleteRequestDraft,
	useUnarchiveRequest,
} from "../../../domain/agent/request/api";
import { BondRequestPreview } from "../../../domain/agent/request/types";

type RequestsActionButtonsProps = {
	request: BondRequestPreview;
};

export const RequestsActionButtons = ({ request }: RequestsActionButtonsProps) => {
	const { deleteRequestDraft } = useDeleteRequestDraft();
	const { archiveRequest } = useArchiveRequest();
	const { unarchiveRequest } = useUnarchiveRequest();

	if (request.status === "draft") {
		return (
			<ConfirmationModalClickWrapper
				message="Are you sure you want to delete this request draft?"
				confirmButtonText="Yes, Delete"
				confirmationButtonClassName="border-[1px] border-red-500 bg-white text-red-500 hover:bg-red-500 hover:bg-opacity-10"
				onConfirm={() => deleteRequestDraft(request.id)}
			>
				<DeleteRowActionButton />
			</ConfirmationModalClickWrapper>
		);
	}
	if (request.archived) {
		return (
			<ConfirmationModalClickWrapper
				message="Are you sure you want to unarchive this request?"
				confirmButtonText="Yes, Unarchive"
				onConfirm={() => unarchiveRequest(request.id)}
			>
				<UnrchiveRowActionButton />
			</ConfirmationModalClickWrapper>
		);
	}
	return (
		<ConfirmationModalClickWrapper
			message="Are you sure you want to archive this request?"
			confirmButtonText="Yes, Archive"
			onConfirm={() => archiveRequest(request.id)}
		>
			<ArchiveRowActionButton />
		</ConfirmationModalClickWrapper>
	);
};

import { ComponentType, ReactNode } from "react";
import { cn } from "../lib/utils";
import { UserMenuProps } from "./UserMenuLayout";

export type WorkflowLayoutProps = {
	userMenu: ComponentType<UserMenuProps>;
	title?: string | ReactNode;
	subHeader?: ReactNode;
	contentClassName?: string;
	children?: ReactNode;
	toolbar?: ReactNode;
};

export const WorkflowLayout = (props: WorkflowLayoutProps) => {
	const { title, subHeader, contentClassName, children, toolbar } = props;
	return (
		<div className="w-full h-full grid grid-rows-[45px_1fr]">
			<div className="w-full h-[45px] flex items-center border border-b-gray-100 text-[13px] text-gray-700 font-medium px-[30px]">
				<div className="flex-1">{title}</div>
				<props.userMenu />
			</div>
			{subHeader}
			<div className="w-full min-w-fit flex-1 flex">
				<div
					className={cn(
						"w-full min-w-fit flex-1 flex justify-center overflow-y-auto relative",
						contentClassName,
					)}
				>
					{children}
				</div>
				<div>{toolbar}</div>
			</div>
		</div>
	);
};

import { Relations } from "@inrev/common";
import type { AdminNoteTopic } from "../../../../../domain/admin/note/types";
import { AdminNotesArchivedSection } from "./archived/AdminNotesArchivedSection";
import { AdminNotesLayout } from "./layouts/AdminNotesLayout";
import { AdminNotesNotesSection } from "./shared/AdminNotesNotesSection";
import { AdminNoteSummarySection } from "./shared/AdminNotesSummarySection";
import { AdminNotesTopicsSection } from "./shared/AdminNotesTopicsSection";

export const AdminNotesDefaultView = ({
	relation,
	defaultTopic,
	topics,
	defaultArchivedTopic,
	archivedTopics,
	notesList,
	setCurrentTopic,
}: {
	relation: Relations.Notes;
	defaultTopic: AdminNoteTopic;
	topics: AdminNoteTopic[];
	defaultArchivedTopic: AdminNoteTopic | undefined;
	archivedTopics: AdminNoteTopic[] | undefined;
	notesList: React.ReactNode;
	setCurrentTopic: (topic: AdminNoteTopic) => void;
}) => {
	return (
		<AdminNotesLayout
			content={
				<>
					<AdminNoteSummarySection
						topicId={defaultTopic.id}
						topicSummary={defaultTopic.summary}
						relation={relation}
						allowUpdate={true}
					/>
					<AdminNotesTopicsSection
						relation={relation}
						topics={topics}
						allowCreate={true}
						setCurrentTopic={setCurrentTopic}
					/>
					<AdminNotesNotesSection
						relation={relation}
						topicId={defaultTopic.id}
						allowCreate={true}
						notesList={notesList}
					/>
				</>
			}
			footer={
				defaultArchivedTopic !== undefined &&
				archivedTopics !== undefined &&
				(defaultArchivedTopic.notes.length > 0 || archivedTopics.length > 0) && (
					<AdminNotesArchivedSection
						relation={relation}
						defaultArchivedTopic={defaultArchivedTopic}
						archivedTopics={archivedTopics}
					/>
				)
			}
		/>
	);
};

import { Dtos } from "@inrev/common";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { match } from "ts-pattern";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { useRequest } from "../../../utils/request";
import { ApiError } from "../../shared/types";

export const useFetchAdminUsers = () => {
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const { data, error, isLoading } = useQuery({
		queryKey: ["adminUsers"],
		queryFn: async () => await get<Dtos.Admin.AdminUser.Get.Response[]>(`/v2/admin/admin-users`),
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { adminUsers: data, adminUsersError: error, adminUsersLoading: isLoading };
};

export const useAdminReassignUnderwriter = () => {
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationKey: ["reassignUnderwriter"],
		mutationFn: async (data: Dtos.Admin.UnderwriterAssignment.Assign.Request) =>
			await post(`/v2/admin/underwriter-assignments`, data, "none"),
		onSuccess: (_data, variables, _context) => {
			match(variables)
				.with({ entityType: "agency" }, (_) =>
					queryClient.invalidateQueries(["adminAgencyPreviews"]),
				)
				.with({ entityType: "account" }, (e) => {
					queryClient.invalidateQueries(["suretyAccounts", e.entityId]);
					queryClient.invalidateQueries(["accountPreviews"]);
				})
				.with({ entityType: "quote" }, (e) => {
					queryClient.invalidateQueries(["bondRequests", e.entityId]);
					queryClient.invalidateQueries(["bondRequestPreviews"]);
				})
				.with({ entityType: "bond" }, (e) => {
					queryClient.invalidateQueries(["bonds", e.entityId]);
					queryClient.invalidateQueries(["bondPreviews"]);
				})
				.exhaustive();
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		reassignUnderwriter: mutation.mutate,
		reassignUnderwriterLoading: mutation.isLoading,
		reassignUnderwriterSuccess: mutation.isSuccess,
	};
};

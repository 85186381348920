import { ComponentType, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableLayoutProps } from "../../../components/layout/TableLayout";
import { UserMenuProps } from "../../../components/layout/UserMenuLayout";
import { DataTable } from "../../../components/ui/DataTable/DataTable";
import { Dropdown } from "../../../components/ui/Dropdown";
import { SearchInput } from "../../../components/ui/SearchInput";
import { useFetchAdminUsers } from "../../../domain/admin/admin-user/api";
import { SuretyBondPreview } from "../../../domain/agent/bond/types";
import { useFetchBondPreviews } from "../../../domain/shared/previews/api";
import { useAuthenticatedUser } from "../../../providers/AuthenticatedUserProvider";
import { bondTableColumns } from "./columns";

type BondsViewProps = {
	userMenu: ComponentType<UserMenuProps>;
	tableLayout: ComponentType<Omit<TableLayoutProps, "includeHeaderActionButtons">>;
};

export const BondsView = (props: BondsViewProps) => {
	const navigate = useNavigate();
	const { user } = useAuthenticatedUser();
	const { adminUsers } = useFetchAdminUsers();
	const underwriterFilterOptions = useMemo(() => {
		return [
			{ value: user.id, label: "Me" },
			...(adminUsers ?? [])
				.filter((u) => u.id !== user.id)
				.map((u) => ({
					value: u.id,
					label: `${u.firstName} ${u.lastName}`,
				})),
		];
	}, [adminUsers, user]);
	const [statusFilterValue, setStatusFilterValue] = useState<string | undefined>(undefined);
	const [statusColumnFilter, setStatusColumnFilter] = useState<
		[{ id: "status"; value: string }] | []
	>([]);
	const [underwriterFilterValue, setUnderwriterFilterValue] = useState<string | undefined>(
		undefined,
	);
	const [underwriterColumnFilter, setUnderwriterColumnFilter] = useState<
		[{ id: "underwriter"; value: string }] | []
	>([]);
	const [searchString, setSearchString] = useState<string>("");
	const { bondPreviews } = useFetchBondPreviews();

	useEffect(() => {
		if (statusFilterValue === undefined) {
			setStatusColumnFilter([]);
		} else setStatusColumnFilter([{ id: "status", value: statusFilterValue }]);
	}, [statusFilterValue]);

	useEffect(() => {
		if (underwriterFilterValue === undefined) {
			setUnderwriterColumnFilter([]);
		} else setUnderwriterColumnFilter([{ id: "underwriter", value: underwriterFilterValue }]);
	}, [underwriterFilterValue]);

	const handleRowClick = (row: SuretyBondPreview) => {
		navigate(`/bonds/${row.id}`);
	};

	return (
		<props.tableLayout
			userMenu={props.userMenu}
			title={
				<div className="w-full h-full">
					<div className="flex items-center h-full space-x-[25px]">
						<div className="leading-[23px]">Bonds</div>
						<div className="flex items-center space-x-[12px]">
							<SearchInput
								className="w-[250px] bg-gray-50 border border-gray-300 focus-within:outline-gray-500 focus-within:shadow-none"
								inputClassName="placeholder:text-gray-500"
								placeholder="Search bonds..."
								onChange={(value) => setSearchString(value)}
							/>
							<Dropdown
								value={statusFilterValue}
								placeholder="Status"
								options={[
									{ value: "open", label: "Open" },
									{ value: "closed", label: "Closed" },
								]}
								buttonClassName="gap-[3px] w-fit h-[33px] mb-[1px] py-[5px] pl-[12px] pr-[8px] text-[12px] font-medium text-gray-800 border outline-gray-300 bg-gray-50 bg-opacity-100 hover:brightness-[.98] data-[headlessui-state=open]:border-gray-500 data-[headlessui-state=open]:brightness-[.94] rounded hover:rounded-0"
								buttonIconClassName="text-[13px] text-gray-400"
								optionsClassName="divide-y divide-gray-100/80 shadow-[rgba(0,0,0,0.05)_0px_0px_4px,_rgba(0,0,0,0.08)_0px_2px_8px] outline-gray-500"
								optionClassName="text-[13px] font-medium py-[8px] text-gray-700 hover:bg-gray-100/60"
								onChange={(value) => {
									const val = value === statusFilterValue ? undefined : value;
									setStatusFilterValue(val);
								}}
							/>
							<Dropdown
								placeholder="Underwriter"
								value={underwriterFilterValue}
								options={underwriterFilterOptions}
								buttonClassName="gap-[3px] w-fit mb-[1px] h-[33px] py-[5px] pl-[12px] pr-[8px] text-[12px] font-medium text-gray-800 border outline-gray-200 bg-gray-50 bg-opacity-100 hover:brightness-[.98] data-[headlessui-state=open]:border-gray-500 data-[headlessui-state=open]:brightness-[.94] rounded hover:rounded-0"
								buttonIconClassName="text-[13px] text-gray-400"
								optionsClassName="divide-y divide-gray-100/80 shadow-[rgba(0,0,0,0.05)_0px_0px_4px,_rgba(0,0,0,0.08)_0px_2px_8px] outline-gray-500"
								optionClassName="text-[13px] font-medium py-[8px] text-gray-700 hover:bg-gray-100/60 whitespace-nowrap"
								onChange={(value) => {
									const val = value === underwriterFilterValue ? undefined : value;
									setUnderwriterFilterValue(val);
								}}
							/>
						</div>
					</div>
				</div>
			}
		>
			<div className="w-full h-full flex flex-col space-y-[8px]">
				<div className="w-full flex-1">
					<DataTable
						name="bonds"
						columnDefs={bondTableColumns}
						onRowClick={handleRowClick}
						rowUrl={(row) => `/bonds/${row.id}`}
						columnFilters={[...statusColumnFilter, ...underwriterColumnFilter]}
						searchString={searchString}
						data={bondPreviews}
						loadingMessage="Loading bonds..."
					/>
				</div>
			</div>
		</props.tableLayout>
	);
};

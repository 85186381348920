import { z } from 'zod';
import { exposureSizeSchema, SuretyType, individualOwnerTypeSchema, nameSuffixSchema, IndividualOwnerType, naicsCodeSchema, companyFinancialStatementPreparationMethodSchema, FileType, ExposureSize, contractSuretyAccountUnderwritingModelSizeSchema } from '../../../../enums.mjs';
import { firstName, lastName, validAddressSchema, email, phoneNumber, decryptedSSN, fein } from '../../../../types/opaque.mjs';
import { nonNegativeCurrencySchema, currencySchema, isoDateSchema, uploadedFileCollectionSchema, zswitch, includesSchema } from '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import '../../../../types/entityRelation/entityRelation.mjs';
import { Get } from '../submitted/contract/submitted-contract-surety-account.dto.mjs';

var Submit;
(function (Submit) {
    (function (Request) {
        let Base;
        (function (Base) {
            let Data;
            (function (Data) {
                let Details;
                (function (Details) {
                    Details.schema = z
                        .object({
                        exposureSize: exposureSizeSchema,
                    })
                        .passthrough();
                })(Details = Data.Details || (Data.Details = {}));
                Data.schema = z
                    .object({
                    details: Details.schema,
                })
                    .passthrough();
            })(Data = Base.Data || (Base.Data = {}));
            Base.schema = z
                .object({
                suretyType: z.literal(SuretyType.contract),
                data: Data.schema,
            })
                .passthrough();
        })(Base = Request.Base || (Request.Base = {}));
        let New;
        (function (New) {
            let Data;
            (function (Data) {
                let Details;
                (function (Details) {
                    let Individual;
                    (function (Individual) {
                        const baseSchema = z.object({
                            draftId: z.string().uuid(),
                            type: individualOwnerTypeSchema,
                            firstName: firstName,
                            middleInitial: z.string().length(1).regex(new RegExp("[a-zA-Z]")).nullish(),
                            lastName: lastName,
                            suffix: nameSuffixSchema.nullish(),
                            address: validAddressSchema,
                            email: email.nullish(),
                            ownsAHome: z.boolean().nullish(),
                            spouseDraftId: z.string().uuid().nullish(),
                        });
                        Individual.creditReportPermissionSchema = z.object({
                            id: z.string().uuid(),
                            permission: z.literal(true),
                        });
                        Individual.ownerSchema = baseSchema.extend({
                            type: z.literal(IndividualOwnerType.owner),
                            phone: phoneNumber.nullish(),
                            ssn: decryptedSSN,
                        });
                        Individual.spouseSchema = baseSchema.extend({
                            type: z.literal(individualOwnerTypeSchema.Values.spouse),
                        });
                        Individual.schema = z.discriminatedUnion("type", [
                            Individual.ownerSchema,
                            Individual.spouseSchema,
                        ]);
                    })(Individual = Details.Individual || (Details.Individual = {}));
                    let Company;
                    (function (Company) {
                        Company.schema = z.object({
                            draftId: z.string().uuid(),
                            name: z.string(),
                            address: validAddressSchema,
                            fein: fein.nullish(),
                        });
                    })(Company = Details.Company || (Details.Company = {}));
                    Details.baseSchema = Base.Data.Details.schema.extend({
                        companies: z.array(Company.schema),
                        individuals: z.array(Individual.schema),
                        fundNames: z.array(z.string()),
                        creditReportPermission: z.array(Individual.creditReportPermissionSchema),
                    });
                })(Details = Data.Details || (Data.Details = {}));
                let History;
                (function (History) {
                    let Small;
                    (function (Small) {
                        Small.schema = z.object({
                            naicsCodes: z.array(naicsCodeSchema).min(1, "At least one required"),
                            largestProjectCompleted: nonNegativeCurrencySchema,
                            failedToCompleteAProject: z.boolean(),
                            lostAPaymentSuit: z.boolean(),
                            causedSuretyLoss: z.boolean(),
                        });
                        (function (Partial) {
                            Partial.schema = Small.schema.partial();
                        })(Small.Partial || (Small.Partial = {}));
                    })(Small = History.Small || (History.Small = {}));
                    let Medium;
                    (function (Medium) {
                        Medium.schema = Small.schema.extend({
                            constructionProjectGrossLoss: z.boolean(),
                            currentConstructionProjectGrossLoss: z.boolean(),
                        });
                        (function (Partial) {
                            Partial.schema = Medium.schema.partial();
                        })(Medium.Partial || (Medium.Partial = {}));
                    })(Medium = History.Medium || (History.Medium = {}));
                    (function (Large) {
                        Large.schema = Medium.schema;
                        (function (Partial) {
                            Partial.partialSchema = Medium.Partial.schema;
                        })(Large.Partial || (Large.Partial = {}));
                    })(History.Large || (History.Large = {}));
                })(History = Data.History || (Data.History = {}));
                let Financials;
                (function (Financials) {
                    let BalanceSheet;
                    (function (BalanceSheet) {
                        let Large;
                        (function (Large) {
                            Large.schema = z.object({
                                corporateCash: currencySchema,
                                currentAssets: currencySchema,
                                currentLiabilities: currencySchema,
                                totalAssets: currencySchema,
                                totalLiabilities: currencySchema,
                                accountsPayable: currencySchema,
                                accountsReceivable: currencySchema,
                                underbillings: currencySchema,
                                creditCardsPayable: currencySchema,
                                bankLinePayable: currencySchema,
                                accruedExpenses: currencySchema,
                                overbillings: currencySchema,
                                currentPortionOfLtd: currencySchema,
                                termLoanDebt: currencySchema,
                            });
                        })(Large = BalanceSheet.Large || (BalanceSheet.Large = {}));
                        (function (Medium) {
                            Medium.schema = Large.schema.pick({
                                corporateCash: true,
                                currentAssets: true,
                                currentLiabilities: true,
                                totalAssets: true,
                                totalLiabilities: true,
                            });
                        })(BalanceSheet.Medium || (BalanceSheet.Medium = {}));
                    })(BalanceSheet = Financials.BalanceSheet || (Financials.BalanceSheet = {}));
                    let IncomeStatement;
                    (function (IncomeStatement) {
                        let Large;
                        (function (Large) {
                            Large.schema = z.object({
                                revenue: currencySchema,
                                gaExpense: currencySchema,
                                profitableYtd: z.boolean(),
                                profitableLastFye: z.boolean(),
                                profitableYearPriorToLastFiscalYear: z.boolean(),
                            });
                        })(Large = IncomeStatement.Large || (IncomeStatement.Large = {}));
                        let Medium;
                        (function (Medium) {
                            Medium.schema = Large.schema.pick({
                                revenue: true,
                                profitableYtd: true,
                                profitableLastFye: true,
                            });
                        })(Medium = IncomeStatement.Medium || (IncomeStatement.Medium = {}));
                        (function (Small) {
                            Small.schema = Medium.schema.pick({
                                profitableYtd: true,
                                profitableLastFye: true,
                            });
                        })(IncomeStatement.Small || (IncomeStatement.Small = {}));
                    })(IncomeStatement = Financials.IncomeStatement || (Financials.IncomeStatement = {}));
                    let BankDetails;
                    (function (BankDetails) {
                        BankDetails.schema = z.object({
                            blocSize: currencySchema,
                        });
                    })(BankDetails = Financials.BankDetails || (Financials.BankDetails = {}));
                    let WorkInProgressStatement;
                    (function (WorkInProgressStatement) {
                        let Medium;
                        (function (Medium) {
                            Medium.schema = z.object({
                                projectBacklogCost: currencySchema,
                            });
                        })(Medium = WorkInProgressStatement.Medium || (WorkInProgressStatement.Medium = {}));
                        (function (Large) {
                            Large.schema = Medium.schema.extend({
                                projectBacklogGrossProfit: currencySchema,
                            });
                        })(WorkInProgressStatement.Large || (WorkInProgressStatement.Large = {}));
                    })(WorkInProgressStatement = Financials.WorkInProgressStatement || (Financials.WorkInProgressStatement = {}));
                    let Small;
                    (function (Small) {
                        Small.schema = z.object({
                            companyDraftId: z.string().uuid(),
                            incomeStatement: IncomeStatement.Small.schema,
                        });
                    })(Small = Financials.Small || (Financials.Small = {}));
                    let Medium;
                    (function (Medium) {
                        Medium.schema = Small.schema.extend({
                            statementDate: isoDateSchema,
                            preparationMethod: companyFinancialStatementPreparationMethodSchema,
                            balanceSheet: BalanceSheet.Medium.schema,
                            incomeStatement: IncomeStatement.Medium.schema,
                            wip: WorkInProgressStatement.Medium.schema,
                            files: uploadedFileCollectionSchema([FileType.fye_company_balance_sheet, FileType.fye_company_income_statement], [
                                FileType.fye_company_balance_sheet,
                                FileType.fye_company_income_statement,
                                FileType.fye_company_ap_aging,
                                FileType.fye_company_ar_aging,
                                FileType.fye_company_cash_flow_statement,
                                FileType.other_company_financial_statement,
                            ]),
                        });
                    })(Medium = Financials.Medium || (Financials.Medium = {}));
                    (function (Large) {
                        Large.schema = Medium.schema.extend({
                            balanceSheet: BalanceSheet.Large.schema,
                            incomeStatement: IncomeStatement.Large.schema,
                            bankDetails: BankDetails.schema,
                            wip: WorkInProgressStatement.Large.schema,
                        });
                    })(Financials.Large || (Financials.Large = {}));
                })(Financials = Data.Financials || (Data.Financials = {}));
                let Small;
                (function (Small) {
                    Small.schema = z.object({
                        details: Details.baseSchema.extend({
                            exposureSize: z.literal(ExposureSize.small),
                        }),
                        history: History.Small.schema,
                        financials: Financials.Small.schema,
                    });
                })(Small = Data.Small || (Data.Small = {}));
                let Medium;
                (function (Medium) {
                    Medium.schema = Small.schema.extend({
                        details: Details.baseSchema.extend({
                            exposureSize: z.literal(ExposureSize.medium),
                        }),
                        history: History.Medium.schema,
                        financials: Financials.Medium.schema,
                    });
                })(Medium = Data.Medium || (Data.Medium = {}));
                let Large;
                (function (Large) {
                    Large.schema = Medium.schema.extend({
                        details: Details.baseSchema.extend({
                            exposureSize: z.literal(ExposureSize.large),
                        }),
                        history: History.Large.schema,
                        financials: Financials.Large.schema,
                    });
                })(Large = Data.Large || (Data.Large = {}));
                Data.schema = zswitch((input) => {
                    const baseSchema = z.object({
                        details: z.object({ exposureSize: contractSuretyAccountUnderwritingModelSizeSchema }),
                    });
                    const parsedBaseInput = baseSchema.safeParse(input);
                    if (!parsedBaseInput.success) {
                        return z.never();
                    }
                    const exposureSize = parsedBaseInput.data.details.exposureSize;
                    switch (exposureSize) {
                        case ExposureSize.small:
                            return Small.schema;
                        case ExposureSize.medium:
                            return Medium.schema;
                        case ExposureSize.large:
                            return Large.schema;
                        default:
                            return z.never();
                    }
                });
            })(Data = New.Data || (New.Data = {}));
            New.schema = z.object({
                suretyType: z.literal(SuretyType.contract),
                data: Data.schema,
            });
        })(New = Request.New || (Request.New = {}));
        (function (Existing) {
            let Data;
            (function (Data) {
                Data.buildDetailsDataValidationSchema = (detailsFormSchema) => Base.Data.Details.schema.extend({
                    creditReportPermission: includesSchema(detailsFormSchema?.include?.creditReportPermission, z.array(New.Data.Details.Individual.creditReportPermissionSchema)),
                });
                Data.buildHistoryDataValidationSchema = (historyFormSchema) => z.object({
                    naicsCodes: includesSchema(historyFormSchema?.include?.naicsCodes, z.array(naicsCodeSchema).min(1, "At least one required")),
                    largestProjectCompleted: includesSchema(historyFormSchema?.include?.largestProjectCompleted, currencySchema),
                    failedToCompleteAProject: includesSchema(historyFormSchema?.include?.failedToCompleteAProject, z.boolean()),
                    constructionProjectGrossLoss: includesSchema(historyFormSchema?.include?.constructionProjectGrossLoss, z.boolean()),
                    currentConstructionProjectGrossLoss: includesSchema(historyFormSchema?.include?.currentConstructionProjectGrossLoss, z.boolean()),
                    lostAPaymentSuit: includesSchema(historyFormSchema?.include?.lostAPaymentSuit, z.boolean()),
                    causedSuretyLoss: includesSchema(historyFormSchema?.include?.causedSuretyLoss, z.boolean()),
                });
                Data.buildFinancialsDataValidationSchema = (financialsFormSchema) => z.object({
                    companyDraftId: includesSchema(financialsFormSchema?.include?.companyDraftId, z.string().uuid()),
                    statementDate: includesSchema(financialsFormSchema?.include?.statementDate, isoDateSchema),
                    preparationMethod: includesSchema(financialsFormSchema?.include?.preparationMethod, companyFinancialStatementPreparationMethodSchema),
                    balanceSheet: includesSchema(financialsFormSchema?.include?.balanceSheet, z.object({
                        corporateCash: includesSchema(financialsFormSchema?.include?.balanceSheet?.corporateCash, currencySchema),
                        accountsReceivable: includesSchema(financialsFormSchema?.include?.balanceSheet?.accountsReceivable, currencySchema),
                        underbillings: includesSchema(financialsFormSchema?.include?.balanceSheet?.underbillings, currencySchema),
                        currentAssets: includesSchema(financialsFormSchema?.include?.balanceSheet?.currentAssets, currencySchema),
                        totalAssets: includesSchema(financialsFormSchema?.include?.balanceSheet?.totalAssets, currencySchema),
                        accountsPayable: includesSchema(financialsFormSchema?.include?.balanceSheet?.accountsPayable, currencySchema),
                        creditCardsPayable: includesSchema(financialsFormSchema?.include?.balanceSheet?.creditCardsPayable, currencySchema),
                        bankLinePayable: includesSchema(financialsFormSchema?.include?.balanceSheet?.bankLinePayable, currencySchema),
                        accruedExpenses: includesSchema(financialsFormSchema?.include?.balanceSheet?.accruedExpenses, currencySchema),
                        overbillings: includesSchema(financialsFormSchema?.include?.balanceSheet?.overbillings, currencySchema),
                        currentPortionOfLtd: includesSchema(financialsFormSchema?.include?.balanceSheet?.currentPortionOfLtd, currencySchema),
                        currentLiabilities: includesSchema(financialsFormSchema?.include?.balanceSheet?.currentLiabilities, currencySchema),
                        termLoanDebt: includesSchema(financialsFormSchema?.include?.balanceSheet?.termLoanDebt, currencySchema),
                        totalLiabilities: includesSchema(financialsFormSchema?.include?.balanceSheet?.totalLiabilities, currencySchema),
                    })),
                    incomeStatement: includesSchema(financialsFormSchema?.include?.incomeStatement, z.object({
                        revenue: includesSchema(financialsFormSchema?.include?.incomeStatement?.revenue, currencySchema),
                        gaExpense: includesSchema(financialsFormSchema?.include?.incomeStatement?.gaExpense, currencySchema),
                        profitableYtd: includesSchema(financialsFormSchema?.include?.incomeStatement?.profitableYtd, z.boolean()),
                        profitableLastFye: includesSchema(financialsFormSchema?.include?.incomeStatement?.profitableLastFye, z.boolean()),
                        profitableYearPriorToLastFiscalYear: includesSchema(financialsFormSchema?.include?.incomeStatement
                            ?.profitableYearPriorToLastFiscalYear, z.boolean()),
                    })),
                    wip: includesSchema(financialsFormSchema?.include?.wip, z.object({
                        projectBacklogCost: includesSchema(financialsFormSchema?.include?.wip?.projectBacklogCost, currencySchema),
                        projectBacklogGrossProfit: includesSchema(financialsFormSchema?.include?.wip?.projectBacklogGrossProfit, currencySchema),
                    })),
                    bankDetails: includesSchema(financialsFormSchema?.include?.bankDetails, z.object({
                        blocSize: includesSchema(financialsFormSchema?.include?.bankDetails?.blocSize, currencySchema),
                    })),
                    files: includesSchema(financialsFormSchema?.include?.files, uploadedFileCollectionSchema(financialsFormSchema?.include.files?.requiredFileTypes, financialsFormSchema?.include.files?.allowedFileTypes, financialsFormSchema?.include.files?.allowedFileExtensions)),
                });
                Data.buildDataValidationSchema = (formSchema) => z.object({
                    details: includesSchema(formSchema.details, Data.buildDetailsDataValidationSchema(formSchema.details)),
                    history: includesSchema(formSchema.history, Data.buildHistoryDataValidationSchema(formSchema.history)),
                    financials: includesSchema(formSchema.financials, Data.buildFinancialsDataValidationSchema(formSchema.financials)),
                });
            })(Data = Existing.Data || (Existing.Data = {}));
            Existing.buildDtoSchema = (formSchema) => z.object({
                suretyType: z.literal(SuretyType.contract),
                data: Data.buildDataValidationSchema(formSchema),
            });
        })(Request.Existing || (Request.Existing = {}));
    })(Submit.Request || (Submit.Request = {}));
    (function (Response) {
        Response.schema = Get.Response.schema;
    })(Submit.Response || (Submit.Response = {}));
})(Submit || (Submit = {}));

export { Submit };

import {
	AttachmentFile,
	ContractSuretyType,
	SuretyBondStatus,
	contractSuretyTypeToLabelMap,
	defaultAttachmentTypeLabelMap,
} from "@inrev/common";
import { useEffect, useState } from "react";
import { HiArrowDownTray } from "react-icons/hi2";
import { useNavigate, useParams } from "react-router-dom";
import { useGetFileUrl } from "../../../api";
import { AttachmentsSection } from "../../../components/layout/AttachmentsSection";
import { CommentsSection } from "../../../components/layout/CommentsSection";
import { LoadingModal } from "../../../components/layout/LoadingModal";
import { Button } from "../../../components/ui/Button";
import { Icon } from "../../../components/ui/Icon";
import { MoreActionsMenu } from "../../../components/ui/MoreActionsMenu";
import { useFetchBond } from "../../../domain/agent/bond/api";
import { SuretyBond } from "../../../domain/agent/bond/types";
import { useCreateBidToFinalContractSuretyQuoteDraft } from "../../../domain/agent/request/api";
import { useComments } from "../../../domain/shared/comments/api";
import { AgentWorkflowLayout } from "../layout/AgentWorkflowLayout";
import { BondCard } from "./shared/BondCard";
import { CloseBondModal } from "./shared/CloseBondModal";

export const BondViewCore = ({ bond }: { bond: SuretyBond }) => {
	const [attachments, setAttachments] = useState<AttachmentFile[]>(bond.attachments);
	const { comments, commentsLoading, createComment, createCommentLoading } = useComments(
		"bonds",
		bond.id,
	);
	const { getFileUrl: getBondFormFileUrl } = useGetFileUrl();
	const navigate = useNavigate();
	const {
		createdBidToFinalContractSuretyQuoteDraft,
		createBidToFinalContractSuretyQuoteDraft,
		createBidToFinalContractSuretyQuoteDraftIsLoading,
	} = useCreateBidToFinalContractSuretyQuoteDraft();

	useEffect(() => {
		if (createdBidToFinalContractSuretyQuoteDraft !== undefined) {
			navigate(`/requests/${createdBidToFinalContractSuretyQuoteDraft.id}`);
		}
	}, [createdBidToFinalContractSuretyQuoteDraft]);

	return (
		<AgentWorkflowLayout
			title={`${contractSuretyTypeToLabelMap[bond.contractSuretyType]} Bond ${bond.number}`}
			contentClassName="bg-gray-50 bg-opacity-[.45]"
		>
			<div className="flex justify-center w-full h-full overflow-y-auto pt-[25px]">
				<div className="w-[785px] max-w-[785px] h-fit flex flex-col items-center space-y-[52px] pb-[125px]">
					<BondCard
						bond={bond}
						actionButtons={
							<div className="flex flex-col space-y-[4px] max-w-[165px]">
								{bond.contractSuretyType === ContractSuretyType.bid &&
								bond.status === SuretyBondStatus.open ? (
									<Button
										color="light-blue"
										shadow
										rounded
										onClick={() => createBidToFinalContractSuretyQuoteDraft(bond.id)}
									>
										Request Final Bond
									</Button>
								) : bond.status === "open" ? (
									<CloseBondModal
										bond={{
											id: bond.id,
											suretyType: bond.suretyType,
											contractSuretyType: bond.contractSuretyType,
											bondType: bond.contractSuretyType,
											amount: bond.amount,
											number: bond.number,
										}}
										button={
											<Button
												color="light-gray"
												filled
												rounded
												className="w-full border-gray-200 border"
											>
												<div className="flex items-center space-x-[8px]">
													<Icon
														type="shield-slash"
														className="stroke-[1] text-gray-500 h-[14px]"
														width={13}
														height={17}
													/>
													<span>Close Bond</span>
												</div>
											</Button>
										}
									/>
								) : (
									<></>
								)}
								<MoreActionsMenu
									items={[
										...(() => {
											if (
												bond.status === "open" &&
												bond.contractSuretyType === ContractSuretyType.bid
											) {
												return [
													{
														el: (
															<CloseBondModal
																bond={{
																	id: bond.id,
																	suretyType: bond.suretyType,
																	contractSuretyType: bond.contractSuretyType,
																	bondType: bond.contractSuretyType,
																	amount: bond.amount,
																	number: bond.number,
																}}
																button={
																	<div className="flex items-center rounded-md hover:bg-gray-200/55 space-x-[8px] px-[8px] py-[5.5px] text-[13px] font-medium text-gray-700 cursor-pointer">
																		<span className="w-[16px] h-full">
																			<Icon
																				type="shield-slash"
																				className="stroke-[1] text-gray-500 h-[14px]"
																				width={12}
																				height={15}
																			/>
																		</span>
																		<span>Close Bond</span>
																	</div>
																}
															/>
														),
													},
												];
											}
											return [];
										})(),
										{
											label: "Download Bond",
											icon: <HiArrowDownTray className="text-[15px]" />,
											onClick: () =>
												getBondFormFileUrl({
													baseUrl: `/v2/surety/bonds/${bond.id}/bond-form`,
													queryKey: ["bondFormFileUrl", bond.id, true],
													asDownload: true,
												}),
										},
									]}
									panelClassName="mt-[10px]"
								/>
							</div>
						}
					/>
					<CommentsSection
						comments={comments}
						commentsLoading={commentsLoading}
						createComment={createComment}
						createCommentLoading={createCommentLoading}
						commentDownloadHeader={`${bond.displayTitle} For ${bond.principalName}`}
					/>
					<AttachmentsSection
						attachments={attachments}
						downloadAllName={`${bond.accountName} ${bond.displayTitle} Attachments`}
						typeLabelMap={defaultAttachmentTypeLabelMap}
						preventDelete
						upload={{
							url: `/v2/surety/bonds/${bond.id}/attachments`,
							onChange: (val) => setAttachments(val),
							allowedTypesAndLabels: defaultAttachmentTypeLabelMap,
							invalidateQueryKeys: [["bonds", bond.id]],
						}}
						download={{
							baseUrl: `/v2/surety/bonds/${bond.id}/attachments`,
							baseQueryKey: "bondRequestAttachments",
						}}
					/>
					{createBidToFinalContractSuretyQuoteDraftIsLoading && <LoadingModal />}
				</div>
			</div>
		</AgentWorkflowLayout>
	);
};

export const BondView = () => {
	const { id } = useParams();
	const { bond, bondError, bondLoading } = useFetchBond(id!);
	const navigate = useNavigate();

	useEffect(() => {
		if (bondError) {
			navigate("/bonds");
		}
	}, [bondError]);

	if (bondLoading || bond === undefined) return <LoadingModal />;
	return <BondViewCore bond={bond} />;
};

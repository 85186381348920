import { z } from 'zod';
import { Get as Get$1, Create as Create$1, Update as Update$1 } from './contract/draft-contract-surety-quote.dto.mjs';
import * as draftContractSuretyQuote_dto from './contract/draft-contract-surety-quote.dto.mjs';
export { draftContractSuretyQuote_dto as Contract };
import { suretyAccountId, individualContactId } from '../../../../types/opaque.mjs';
import '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import '../../../../types/entityRelation/entityRelation.mjs';

var Get;
(function (Get) {
    Get.responseSchema = Get$1.responseSchema;
})(Get || (Get = {}));
var Create;
(function (Create) {
    Create.requestSchema = z.union([
        z.object({
            suretyType: z.literal("placeholder"),
        }).and(z.union([
            z.object({
                accountId: suretyAccountId,
                principalIndividualId: individualContactId
            }),
            z.object({
                accountId: z.undefined(),
                principalCompanyId: z.undefined()
            })
        ])),
        Create$1.requestSchema
    ]);
    Create.responseSchema = Create$1.responseSchema;
})(Create || (Create = {}));
var Update;
(function (Update) {
    Update.requestSchema = Update$1.requestSchema;
    Update.responseSchema = Get.responseSchema;
})(Update || (Update = {}));

export { Create, Get, Update };

import { Navigate, Route, Routes } from "react-router-dom";
import { Tooltip as TT } from "react-tooltip";
import { PrefetchProvider } from "./providers/AgentPrefetchProvider";
import { AuthGuard, RBACAuthGuard } from "./utils/guards";
import { SuretyAccountView } from "./views/agent/account/AccountView";
import { AccountsView } from "./views/agent/accounts/AccountsView";
import { BondView } from "./views/agent/bond/BondView";
import { BondsView } from "./views/agent/bonds/BondsView";
import { DashboardView } from "./views/agent/dashboard/DashboardView";
import { AgentMainLayout } from "./views/agent/layout/AgentMainLayout";
import { AgentTableLayout } from "./views/agent/layout/AgentTableLayout";
import { BondRequestView } from "./views/agent/request/BondRequestView";
import { RequestsView } from "./views/agent/requests/RequestsView";
import { AgentUserMenu } from "./views/agent/settings/AgentUserMenu";
import { MyTeamView } from "./views/agent/settings/MyTeamView/MyTeamView";

export const AgentApp = () => {
	return (
		<PrefetchProvider>
			<AgentMainLayout>
				<Routes>
					<Route path="/" element={<AuthGuard component={DashboardView} />} />
					<Route
						path="requests"
						element={
							<AuthGuard
								component={() => (
									<RequestsView userMenu={AgentUserMenu} tableLayout={AgentTableLayout} />
								)}
							/>
						}
					/>
					<Route path="requests/:id" element={<AuthGuard component={BondRequestView} />} />
					<Route
						path="bonds"
						element={
							<AuthGuard
								component={() => (
									<BondsView userMenu={AgentUserMenu} tableLayout={AgentTableLayout} />
								)}
							/>
						}
					/>
					<Route path="bonds/:id" element={<AuthGuard component={BondView} />} />
					<Route
						path="accounts"
						element={
							<AuthGuard
								component={() => (
									<AccountsView userMenu={AgentUserMenu} tableLayout={AgentTableLayout} />
								)}
							/>
						}
					/>
					<Route path="accounts/:id" element={<AuthGuard component={SuretyAccountView} />} />
					<Route
						path="team"
						element={
							<RBACAuthGuard
								roles={["agent-admin"]}
								rbacRedirectTo="/"
								component={() => <MyTeamView />}
							/>
						}
					/>
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</AgentMainLayout>
			<TT anchorSelect="[id^='tt-']" />
		</PrefetchProvider>
	);
};

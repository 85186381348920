import { ContractSuretyBondFormType } from "@inrev/common";
import { useFormContext } from "react-hook-form";
import { type Section } from "../../../../../../components/layout/ShrinkingHeaderSectionNavLayout";
import { ShrinkingHeaderSectionSchemaNavLayout } from "../../../../../../components/layout/ShrinkingHeaderSectionSchemaNavLayout";
import { useFetchBond } from "../../../../../../domain/agent/bond/api";
import {
	BondFormTemplate,
	BondRequestDraftData,
} from "../../../../../../domain/agent/request/types";
import { AgentWorkflowLayout } from "../../../../layout/AgentWorkflowLayout";
import { DraftBidToFinalBondRequestBondSection } from "./sections/DraftBidToFinalBondRequestBondSection";
import { DraftBidToFinalBondRequestSummarySection } from "./sections/DraftBidToFinalBondRequestSummarySection";
import type { BidToFinalBondRequestDraft } from "./types";

type DraftBidToFinalBondRequestViewProps = {
	request: BidToFinalBondRequestDraft;
	bondFormTypesAndLabels: Record<ContractSuretyBondFormType, string>;
	bondFormTemplates: BondFormTemplate[];
	principalName?: string;
	onSubmit: () => void;
};

export const DraftBidToFinalBondRequestView = ({
	request,
	principalName,
	onSubmit,
}: DraftBidToFinalBondRequestViewProps) => {
	const { bond: bidBond } = useFetchBond(request.draft.data.bond.bidBondId);
	const formMethods = useFormContext<BondRequestDraftData>();

	const sectionMap: { [K in keyof BidToFinalBondRequestDraft["draft"]["schema"]]: Section<K> } = {
		bond: {
			name: "bond",
			navLabel: "Bond",
			navError: !!formMethods.formState.errors.bond,
			component: <DraftBidToFinalBondRequestBondSection />,
			headerTitle: "Bond",
		},
	};

	if (!bidBond) return <></>;
	return (
		<AgentWorkflowLayout
			title={
				<div className="flex items-center space-x-[5px]">
					<span>Final Bond Request from Bid Bond {bidBond.number}</span>
					{!!principalName && (
						<>
							<span className="text-gray-300">|</span>
							<span className="text-gray-500 text-[13px]">{principalName}</span>
						</>
					)}
				</div>
			}
		>
			<ShrinkingHeaderSectionSchemaNavLayout
				schema={request.draft.schema}
				sectionMap={sectionMap}
				onSubmit={onSubmit}
				confirmationMessage="Are you sure you want to submit this bond request?"
				summarySectionComponent={DraftBidToFinalBondRequestSummarySection}
			/>
		</AgentWorkflowLayout>
	);
};

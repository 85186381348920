import { Relations } from "@inrev/common";
import { useState } from "react";
import { HiOutlineArchiveBox, HiOutlineTrash } from "react-icons/hi2";
import { ConfirmationModal } from "../../../../../components/layout/ConfirmationModal";
import type { AdminNoteTopic } from "../../../../../domain/admin/note/types";
import { AdminNotesTopicHeader } from "./components/AdminNotesTopicHeader";
import { AdminNotesLayout } from "./layouts/AdminNotesLayout";
import { AdminNotesNotesSection } from "./shared/AdminNotesNotesSection";
import { AdminNoteSummarySection } from "./shared/AdminNotesSummarySection";

export const AdminNotesTopicView = ({
	relation,
	topic,
	notesList,
	onBack,
	onDelete,
	onArchive,
}: {
	relation: Relations.Notes;
	topic: AdminNoteTopic;
	notesList: React.ReactNode;
	onBack: () => void;
	onDelete?: () => void;
	onArchive?: () => void;
}) => {
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const { actionButton, confirmationMessage, confirmationButtonText, onConfirm } = (() => {
		if (onDelete !== undefined) {
			return {
				actionButton: (
					<div
						className="flex items-center justify-center h-[19px] w-[19px] cursor-pointer text-gray-400 hover:text-red-600"
						onClick={() => setConfirmationOpen(true)}
					>
						<HiOutlineTrash />
					</div>
				),
				confirmationMessage: "Are you sure you want to delete this topic?",
				confirmationButtonText: "Delete",
				onConfirm: onDelete,
			};
		}
		if (onArchive !== undefined) {
			return {
				actionButton: (
					<div
						className="flex items-center justify-center cursor-pointer text-gray-400 hover:text-gray-700 h-[19px] w-[19px]"
						onClick={() => setConfirmationOpen(true)}
					>
						<HiOutlineArchiveBox />
					</div>
				),
				confirmationMessage: "Are you sure you want to archive this topic?",
				confirmationButtonText: "Archive",
				onConfirm: onArchive,
			};
		}
		return {
			actionButton: undefined,
			confirmationMessage: undefined,
			confirmationButtonText: undefined,
			onConfirm: () => {},
		};
	})();

	const header = (
		<>
			<AdminNotesTopicHeader title={topic.name} onBack={onBack} actionButton={actionButton} />
			{confirmationOpen && confirmationMessage && confirmationButtonText && (
				<ConfirmationModal
					message={confirmationMessage}
					cancelButtonText="Cancel"
					confirmButtonText={confirmationButtonText}
					onCancel={() => setConfirmationOpen(false)}
					onConfirm={() => {
						onConfirm();
						setConfirmationOpen(false);
					}}
				/>
			)}
		</>
	);

	const content = (
		<>
			<AdminNoteSummarySection
				topicId={topic.id}
				topicSummary={topic.summary}
				relation={relation}
				allowUpdate={true}
			/>
			<AdminNotesNotesSection
				relation={relation}
				topicId={topic.id}
				allowCreate={true}
				notesList={notesList}
			/>
		</>
	);

	return <AdminNotesLayout header={header} content={content} />;
};

import { UpdateStatus } from './contract-surety-quote-mixins.dto.mjs';
import '../../../../../types/opaque.mjs';
import { rateSchema } from '../../../../../types/utils.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../../types/user/user.types.mjs';
import '../../../../../types/entityRelation/entityRelation.mjs';
import { ContractSuretyType } from '../../../../../enums.mjs';
import { z } from 'zod';
import { Approve } from '../../bond-form/contract/final-contract-surety-bond-form.dto.mjs';

var Final;
(function (Final) {
    (function (UpdateStatus$1) {
        (function (Request) {
            Request.approveSchema = UpdateStatus.Request.baseApproveSchema.extend({
                contractSuretyType: z.union([z.literal(ContractSuretyType.final), z.literal(ContractSuretyType.bid_to_final)]),
                rate: rateSchema,
                bondForm: Approve.Request.schema.omit({ suretyType: true, contractSuretyType: true }).optional()
            });
        })(UpdateStatus$1.Request || (UpdateStatus$1.Request = {}));
    })(Final.UpdateStatus || (Final.UpdateStatus = {}));
})(Final || (Final = {}));

export { Final };

import { z } from 'zod';
import { Get as Get$1, Close as Close$2 } from './bid-contract-surety-bond.dto.mjs';
import * as bidContractSuretyBond_dto from './bid-contract-surety-bond.dto.mjs';
export { bidContractSuretyBond_dto as Bid };
import { Get as Get$2, Close as Close$1 } from './final-contract-surety-bond.dto.mjs';
import * as finalContractSuretyBond_dto from './final-contract-surety-bond.dto.mjs';
export { finalContractSuretyBond_dto as Final };
import '../../../../types/opaque.mjs';
import { zswitch } from '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import '../../../../types/entityRelation/entityRelation.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = z.discriminatedUnion("contractSuretyType", [
            Get$1.Response.schema,
            Get$2.Response.schema
        ]);
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Close;
(function (Close) {
    (function (Request) {
        Request.schema = zswitch(input => {
            switch (input.contractSuretyType) {
                case "bid":
                    return Close$2.Request.schema;
                case "final":
                    return Close$1.Request.schema;
                default:
                    return z.never();
            }
        });
    })(Close.Request || (Close.Request = {}));
})(Close || (Close = {}));

export { Close, Get };

import type { NoteTopicId, Relations } from "@inrev/common";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useRef, useState } from "react";
import { BiBold, BiItalic, BiListOl, BiListUl } from "react-icons/bi";
import { FaUnderline } from "react-icons/fa";
import { cn } from "../../../../../../components/lib/utils";
import { useUpdateAdminNoteTopic } from "../../../../../../domain/admin/note/api";
import { assertIsNode } from "../../../../../../utils";

export const AdminNoteSummarySection = ({
	relation,
	topicId,
	topicSummary,
	allowUpdate,
}: {
	relation: Relations.Notes;
	topicId: NoteTopicId;
	topicSummary: string;
	allowUpdate: boolean;
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const { updateAdminNoteTopic } = useUpdateAdminNoteTopic();

	const wrapperRef = useRef<HTMLDivElement>(null);

	// Used to detect if a user clicks outside of the editor and closes it without needing to use the onBlur event
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			assertIsNode(event.target);
			if (wrapperRef?.current && !wrapperRef.current.contains(event.target)) {
				setIsEditing(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef]);

	const editor = useEditor(
		{
			editable: true,
			content: topicSummary,
			extensions: [
				StarterKit.configure({
					bold: {
						HTMLAttributes: {
							class: "font-[550]",
						},
					},
				}),
				Underline,
				Placeholder.configure({
					placeholder: "Enter a summary...",
					emptyEditorClass:
						"before:h-0 before:float-left before:content-[attr(data-placeholder)] before:text-gray-400 before:italic cursor-text",
				}),
			],
			editorProps: {
				attributes: {
					class: "outline-none focus:outline-none py-[12px] px-[16px] min-h-[100px] text-[14px]",
				},
			},
			onBlur: () => {
				if (editor?.getHTML()) {
					if (topicSummary === editor.getHTML() || editor.getHTML() === "<p></p>") {
						return;
					}
					updateAdminNoteTopic({
						data: {
							summary: editor.getHTML(),
						},
						relation,
						noteTopicId: topicId,
					});
					topicSummary = editor.getHTML();
				}
				setIsEditing(false);
			},
		},
		[topicSummary],
	);

	// Used to focus the end of the editor when the user double clicks on the summary
	useEffect(() => {
		if (isEditing && editor) {
			editor.commands.focus("end");
		}
	}, [isEditing]);

	return (
		<div className={cn("flex flex-col gap-[16px] w-full")}>
			<p className={cn("text-gray-400 text-[12px]")}>Summary</p>
			{!isEditing && (
				<div
					className="text-gray-800 text-[13px] text-[375] cursor-default select-none"
					onDoubleClick={() => {
						allowUpdate && setIsEditing(true);
					}}
					dangerouslySetInnerHTML={{
						__html: topicSummary.length > 0 ? filterXSS(topicSummary) : "<p>--</p>",
					}}
				/>
			)}
			{isEditing && editor && (
				<div
					tabIndex={1}
					className={cn(" bg-white rounded-md border border-slate-200 !mx-[-12px]")}
				>
					<div className="flex items-center h-[32px] px-[4px] space-x-[3px] border-b border-b-gray-100 justify-between bg-gray-50 rounded-t-md">
						<div className="flex items-center space-x-[3px] min-w-fit">
							<div
								onMouseDown={(e) => {
									e.preventDefault();
									e.stopPropagation();
									editor.chain().focus().toggleBold().run();
								}}
								className={cn(
									"flex items-center justify-center w-[26px] h-[26px] rounded text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer",
									editor.isActive("bold")
										? "bg-gray-200/80 hover:bg-gray-200/80 text-gray-900"
										: "hover:bg-gray-100 hover:text-gray-900",
								)}
							>
								<BiBold className="text-[15px]" />
							</div>
							<div
								onMouseDown={(e) => {
									e.preventDefault();
									e.stopPropagation();
									editor.chain().focus().toggleItalic().run();
								}}
								className={cn(
									"flex items-center justify-center w-[26px] h-[26px] rounded text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer",
									editor.isActive("italic")
										? "bg-gray-200/80 hover:bg-gray-200/80 text-gray-900"
										: "hover:bg-gray-100 hover:text-gray-900",
								)}
							>
								<BiItalic className="text-[14px] mt-[-.75px]" />
							</div>
							<div
								onMouseDown={(e) => {
									e.preventDefault();
									e.stopPropagation();
									editor.chain().focus().toggleUnderline().run();
								}}
								className={cn(
									"flex items-center justify-center w-[26px] h-[26px] rounded text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer",
									editor.isActive("underline")
										? "bg-gray-200/80 hover:bg-gray-200/80 text-gray-900"
										: "hover:bg-gray-100 hover:text-gray-800",
								)}
							>
								<FaUnderline className="text-[11.5px] mt-[1px]" />
							</div>
							<div
								onMouseDown={(e) => {
									e.preventDefault();
									e.stopPropagation();
									editor.chain().focus().toggleBulletList().run();
								}}
								className={cn(
									"flex items-center justify-center w-[26px] h-[26px] rounded text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer",
									editor.isActive("bulletList")
										? "bg-gray-200/80 hover:bg-gray-200/80 text-gray-900"
										: "hover:bg-gray-100 hover:text-gray-800",
								)}
							>
								<BiListUl className="text-[18.5px] mt-[1px] mb-[1px]" />
							</div>
							<div
								onMouseDown={(e) => {
									e.preventDefault();
									e.stopPropagation();
									editor.chain().focus().toggleOrderedList().run();
								}}
								className={cn(
									"flex items-center justify-center w-[26px] h-[26px] rounded text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer",
									editor.isActive("orderedList")
										? "bg-gray-200/80 hover:bg-gray-200/80 text-gray-900"
										: "hover:bg-gray-100 hover:text-gray-800",
								)}
							>
								<BiListOl className="text-[18.5px] mt-[1px] mb-[1px]" />
							</div>
						</div>
					</div>
					<EditorContent editor={editor} ref={wrapperRef} />
				</div>
			)}
		</div>
	);
};

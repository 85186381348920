import { Dtos, Types, UserId } from "@inrev/common";
import { useState } from "react";
import { match } from "ts-pattern";
import { Modal } from "../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../components/layout/ModalItemWithHeader";
import { Button } from "../../../components/ui/Button";
import { CheckboxOption } from "../../../components/ui/CheckboxOption";
import {
	useAdminReassignUnderwriter,
	useFetchAdminUsers,
} from "../../../domain/admin/admin-user/api";
import { AdminUserSelect } from "./AdminUserSelect";

export type AdminAssigneeControlsProps = {
	assignedEntity: Types.UnderwriterAssignment.UnderwriterAssignmentEntity;
	currentAssignee: UserId;
};

export const AdminAssigneeControls = (props: AdminAssigneeControlsProps) => {
	const [manageAssigneeModalOpen, setManageAssigneeModalOpen] = useState<boolean>(false);
	const { adminUsers = [] } = useFetchAdminUsers();
	const currentAssignee = adminUsers.find((u) => u.id === props.currentAssignee);

	return (
		<>
			<div
				className="flex items-center justify-center w-[28px] h-[28px] text-[12px] font-medium text-gray-700 border border-gray-400 bg-white hover:text-gray-800 hover:border-gray-600 rounded-full cursor-pointer"
				onClick={(e) => {
					e.preventDefault();
					setManageAssigneeModalOpen(true);
				}}
			>
				{currentAssignee
					? `${currentAssignee.firstName[0]}${currentAssignee.lastName[0]}`.toUpperCase()
					: "??"}
			</div>
			{manageAssigneeModalOpen && (
				<ManageAssigneeModal
					{...props}
					onClose={() => setManageAssigneeModalOpen(false)}
					adminUsers={adminUsers}
				/>
			)}
		</>
	);
};

type ManageAssigneeModalProps = {
	assignedEntity: Types.UnderwriterAssignment.UnderwriterAssignmentEntity;
	currentAssignee: UserId;
	adminUsers: Dtos.Admin.AdminUser.Get.Response[];
	onClose: () => void;
};

export const ManageAssigneeModal = ({
	assignedEntity,
	onClose,
	adminUsers,
	currentAssignee,
}: ManageAssigneeModalProps) => {
	const [selectedUserId, setSelectedUserId] = useState<UserId | null>(currentAssignee);
	const [reassignAll, setReassignAll] = useState(false);
	const { reassignUnderwriter, reassignUnderwriterLoading } = useAdminReassignUnderwriter();

	const handleSubmit = () => {
		if (!selectedUserId) return;

		const dto: Dtos.Admin.UnderwriterAssignment.Assign.Request = match(assignedEntity)
			.with({ entityType: "account" }, (assignedEntity) => ({
				...assignedEntity,
				underwriterUserId: selectedUserId,
				reassignAll,
			}))
			.otherwise((assignedEntity) => ({
				...assignedEntity,
				underwriterUserId: selectedUserId,
			}));

		reassignUnderwriter(dto);
		onClose();
	};

	return (
		<Modal onClickOutside={onClose}>
			<ModalItemWithHeader
				onClose={onClose}
				header={
					<div className="flex flex-col">
						<span className="text-[15px] font-[550]">Reassign Underwriter</span>
					</div>
				}
				className="w-[400px]"
				bodyClassName="p-[24px]"
			>
				<div className="flex flex-col space-y-[24px]">
					<div className="flex flex-col items-left">
						<AdminUserSelect
							adminUsers={adminUsers}
							currentAssignee={selectedUserId ?? currentAssignee}
							entity={assignedEntity}
							onChange={setSelectedUserId}
							disabled={reassignUnderwriterLoading}
							className="w-[300px]"
						/>
					</div>

					{assignedEntity.entityType === "account" && (
						<div className="flex justify-left">
							<CheckboxOption
								label="Reassign all requests and bonds?"
								labelClassName="text-[14px]"
								checked={reassignAll}
								onChange={setReassignAll}
							/>
						</div>
					)}

					<div className="flex justify-center space-x-[10px]">
						<Button color="gray" onClick={onClose} className="w-[120px]">
							Cancel
						</Button>
						<Button
							onClick={handleSubmit}
							color="light-blue"
							filled
							className="w-[120px]"
							tabIndex={2}
							loading={reassignUnderwriterLoading}
							disabled={!selectedUserId || reassignUnderwriterLoading}
						>
							Submit
						</Button>
					</div>
				</div>
			</ModalItemWithHeader>
		</Modal>
	);
};

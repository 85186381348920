import { formatToDollar, formatToPercent } from "@inrev/common";
import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { getStatusTagByStatus } from "../../../components/ui/StatusTag";
import { RowActionButtons } from "../../../components/ui/row-action-buttons/RowActionButtons";
import { bondTypeLabelMap } from "../../../constants";
import { SuretyBondPreview } from "../../../domain/agent/bond/types";
import { formatEmpty } from "../../../utils";
import { AdminAssigneeControls } from "../shared/AdminAssigneeControls";

export const bondTableColumns: ColumnDef<SuretyBondPreview>[] = [
	{
		id: "underwriter",
		header: undefined,
		accessorKey: "underwriterUserId",
		cell: (props) => (
			<RowActionButtons alwaysShow={true}>
				<AdminAssigneeControls
					assignedEntity={{ entityType: "bond", entityId: props.row.original.id }}
					currentAssignee={props.row.original.underwriterUserId!}
				/>
			</RowActionButtons>
		),
		meta: {
			noTruncate: true,
		},
		size: 60,
		minSize: 60,
		maxSize: 60,
		enableResizing: false,
		enableSorting: true,
		enableGlobalFilter: false,
		enableHiding: false,
		enableColumnFilter: true,
		enablePinning: false,
		enableGrouping: false,
		enableMultiSort: false,
	},
	{
		id: "status",
		accessorKey: "status",
		header: "Status",
		size: 100,
		minSize: 100,
		filterFn: "equalsString",
		cell: (cell) => getStatusTagByStatus(cell.row.original.status),
	},
	{
		id: "bondNumber",
		accessorKey: "number",
		header: "Bond Number",
		size: 180,
		minSize: 180,
	},
	{
		id: "principalName",
		accessorKey: "principalName",
		accessorFn: ({ principalName }) => formatEmpty(principalName),
		header: "Principal",
		size: 180,
		minSize: 130,
	},
	{
		id: "description",
		accessorKey: "description",
		header: "Description",
		size: 200,
		minSize: 130,
	},
	{
		id: "accountName",
		accessorKey: "accountName",
		accessorFn: ({ accountName }) => formatEmpty(accountName),
		header: "Account",
		size: 180,
		minSize: 180,
	},
	{
		id: "effectiveDate",
		accessorKey: "effectiveDate",
		accessorFn: ({ effectiveDate }) => DateTime.fromISO(effectiveDate).toJSDate(),
		cell: (props) =>
			DateTime.fromISO(props.row.original.effectiveDate).toLocaleString(DateTime.DATE_MED),
		header: "Effective Date",
		size: 150,
		minSize: 130,
		enableGlobalFilter: false,
	},
	{
		id: "expectedCompletionDate",
		accessorKey: "expectedCompletionDate",
		accessorFn: ({ expectedCompletionDate }) =>
			formatEmpty(
				expectedCompletionDate
					? DateTime.fromISO(expectedCompletionDate).toJSDate()
					: expectedCompletionDate,
			),
		cell: (props) =>
			formatEmpty(
				props.row.original.expectedCompletionDate
					? DateTime.fromISO(props.row.original.expectedCompletionDate).toLocaleString(
							DateTime.DATE_MED,
						)
					: props.row.original.expectedCompletionDate,
			),
		size: 200,
		minSize: 130,
		sortingFn: "datetime",
		header: "Estimated Completion Date",
	},
	{
		id: "amount",
		accessorKey: "amount",
		header: "Amount",
		size: 130,
		minSize: 130,
		cell: (context) => formatEmpty(formatToDollar(context.getValue<string>())),
	},
	{
		id: "rate",
		accessorKey: "rate",
		header: "Rate",
		size: 130,
		minSize: 130,
		accessorFn: ({ rate }) => formatToPercent(rate),
	},
	{
		id: "premium",
		accessorKey: "premium",
		header: "Premium",
		size: 130,
		minSize: 130,
		cell: (context) => formatToDollar(context.getValue<string>(), true),
	},
	{
		id: "type",
		accessorKey: "type",
		header: "Type",
		size: 77,
		minSize: 77,
		accessorFn: ({ type }) => bondTypeLabelMap[type],
	},
];

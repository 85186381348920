import { LogoutOptions, useAuth0 } from "@auth0/auth0-react";
import { HiOutlineArrowRightEndOnRectangle } from "react-icons/hi2";
import { AuthenticatedUser } from "../../providers/AuthenticatedUserProvider";
import { Icon } from "../ui/Icon";
import { Menu } from "../ui/Menu";

export type UserMenuProps = {};
export type UserMenuLayout = {
	user: AuthenticatedUser;
	logoutOptions?: LogoutOptions;
	children: React.ReactNode;
};
export const UserMenuLayout = ({ user, logoutOptions, children }: UserMenuLayout) => {
	const { logout } = useAuth0();

	return (
		<Menu
			buttonClassName="flex space-x-2 items-center h-[32px] px-[10px] hover:bg-gray-100 cursor-pointer select-none rounded-sm outline outline-[0px] outline-offset[-1px] group-data-[open]:outline-[1px] group-data-[open]:outline-gray-300 group-data-[open]:bg-gray-100"
			button={
				<>
					<div className="text-sm font-medium text-gray-700">{user.firstName}</div>
					<Icon type="chevron" className="fill-gray-700" width={10} height={5} />
				</>
			}
			panelClassName="mt-[12px]"
			anchor="bottom end"
		>
			<div className="grid grid-flow-row auto-rows-min select-none cursor-pointer min-w-[175px] w-full outline outline-[1px] outline-offset-[-1px] rounded-md bg-white divide-y divide-gray-100/80 outline-gray-300/80">
				{children}
				<div
					className="flex space-x-[10px] items-center text-[14px] w-full min-w-[200px] font-medium px-[20px] py-[12px] text-gray-700 hover:underline"
					onClick={() => logout(logoutOptions)}
				>
					<HiOutlineArrowRightEndOnRectangle className="text-[18px] text-gray-500" />
					<span>Logout</span>
				</div>
			</div>
		</Menu>
	);
};
